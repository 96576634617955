export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "USER_GET_SUCCESS":
    case "USER_GET_ERROR":
      return {
        ...state,
        GetUserListResponse: action.updatePayload,
      };
    case "SWITCH_USER_SUCCESS":
    case "SWITCH_USER_ERROR":
      return {
        ...state,
        SwitchUserResponse: action.updatePayload,
      };
    case "DELETE_USER_SUCCESS":
    case "DELETE_USER_ERROR":
      return {
        ...state,
        DeleteUserResponse: action.updatePayload,
      };
    case "Add_USER_SUCCESS":
    case "Add_USER_ERROR":
      return {
        ...state,
        AddUserResponse: action.updatePayload,
      };
    case "CHANGE_PASSWORD_USER_SUCCESS":
    case "CHANGE_PASSWORD_USER_ERROR":
      return {
        ...state,
        ChangePasswordUserResponse: action.updatePayload,
      };
    case "ADD_FEEDBACK_SUCCESS":
    case "ADD_FEEDBACK_ERROR":
      return {
        ...state,
        AddFeedbackResponse: action.updatePayload,
      };
    case "Get_FEEDBACK_LIST_SUCCESS":
    case "Get_FEEDBACK_LIST_ERROR":
      return {
        ...state,
        GetFeedbackListResponse: action.updatePayload,
      };
    case "RESEND_MAIL_USER_SUCCESS":
    case "RESEND_MAIL_USER_ERROR":
      return {
        ...state,
        ReSendEmailUserResponse: action.updatePayload,
      };
    case "FAKE_ACTION_USER":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
