export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_BULK_PROFITABILITY_LIST_SUCCESS":
    case "GET_BULK_PROFITABILITY_LIST_ERROR":
      return {
        ...state,
        GetBulkProfitabilityListResponse: action.updatePayload,
      };
    case "BULK_PROFITABILITY_IMPORT_SUCCESS":
    case "BULK_PROFITABILITY_IMPORT_ERROR":
      return {
        ...state,
        BulkProfitabilityImportResponse: action.updatePayload,
      };
    case "DELETE_BULK_PROFITABILITY_SUCCESS":
    case "DELETE_BULK_PROFITABILITY_ERROR":
      return {
        ...state,
        DeleteBulkProfitabilityResponse: action.updatePayload,
      };

    case "GET_BULK_PROFITABILITY_SINGLE_SUCCESS":
    case "GET_BULK_PROFITABILITY_SINGLE_ERROR":
      return {
        ...state,
        GetBulkProfitabilitySingleResponse: action.updatePayload,
      };

    case "FAKE_ACTION_BULK_PROFITABILITY":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
