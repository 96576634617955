import { Input, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import ASINLink from "../../../components/router-link";
import { adminMenus, defaultMenus, testArr } from "../../../config";
import AuthWrapper from "./style";

const isModulesDependencies = [
  "listing-optimizer",
  "sales-analytics",
  "inventory",
];
const isAdded = [
  "dashboard",
  "master-table",
  "sp-api-reports",
  // "data-scheduler", // Only Admin can show (user not access this menu)
  // "application-logs", // Only Admin can show (user not access this menu)
];
function Login(props) {
  const focusFirst = useRef();
  const { loginAction, fakeActionAuth } = props;

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [sending, setSending] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [moduleName, setModuleName] = useState("");

  const LoginRes = useSelector((state) => state.Auth.LoginResponse || {});

  useEffect(() => {
    focusFirst?.current?.focus();
    return () => {};
  }, []);

  const onLogin = (e) => {
    e.preventDefault();
    if (username && password) {
      setSending(true);
      let body = {
        username,
        password,
      };
      loginAction(body);
    }
  };

  const checkPlan = () => {
    if (LoginRes?.data?.user_data?.user_type === 1) {
      return adminMenus;
      // }
    } else if (LoginRes?.data?.user_data?.id === 2) {
      return testArr;
    } else if (LoginRes?.data?.user_data?.is_active_subscription === 1) {
      const index = LoginRes?.data?.services
        ?.map((d) => d?.slug)
        .findIndex(
          (d) =>
            d === isModulesDependencies[0] ||
            d === isModulesDependencies[1] ||
            d === isModulesDependencies[2]
        );
      return index === -1
        ? [
            ...["dashboard"],
            ...["reimbursement"],
            ...LoginRes?.data?.services?.map((d) => d?.slug),
            ...defaultMenus,
          ]
        : [
            ...["dashboard"],
            ...["reimbursement"],
            ...LoginRes?.data?.services?.map((d) => d?.slug),
            ...defaultMenus,
            ...isAdded,
          ];
    }
    return defaultMenus;
    // return testArr;
  };
  useEffect(() => {
    window.addEventListener("storage", (e) => {
      const { token, user, userType, plan, menusList } = e?.storageArea;
      if (token && user && userType && plan && menusList) {
        // console.log(JSON.parse(menusList));
        // localStorage.setItem("user", user);
        // localStorage.setItem("token", token);
        // localStorage.setItem("userType", userType);
        // localStorage.setItem("plan", plan);
        // localStorage.setItem("menusList", menusList);
        setModuleName(JSON.parse(menusList)?.[0]);
        setRedirect(true);
      }
    });
    return () => {
      window.removeEventListener("storage", (e) => {});
    };
  }, []);

  useEffect(() => {
    if (Object.keys(LoginRes).length !== 0 && LoginRes?.status) {
      setSending(false);
      localStorage.setItem("user", JSON.stringify(LoginRes?.data?.user_data));
      localStorage.setItem("token", LoginRes?.data?.auth_token);
      localStorage.setItem("userType", LoginRes?.data?.user_data?.user_type);
      localStorage.setItem("plan", LoginRes?.data?.plan?.id);
      localStorage.setItem(
        "subscription_status",
        LoginRes?.data?.user_data?.subscription_status
      );
      localStorage.setItem("menusList", []);
      // localStorage.setItem("menusList", JSON.stringify(checkPlan()));
      setModuleName(LoginRes?.data?.services?.[0]?.slug);
      message.destroy();
      message.success(LoginRes?.message);
      setRedirect(true);
      fakeActionAuth("LoginResponse");
    } else if (Object.keys(LoginRes).length !== 0) {
      setSending(false);
      message.destroy();
      message.error(LoginRes?.message);
      fakeActionAuth("LoginResponse");
    }
  }, [LoginRes]);

  console.log("Module", moduleName);
  if (localStorage.getItem("userType") || redirect) {
    return (
      <Redirect
        to={
          localStorage.getItem("userType") == 1
            ? `/manage-seller-users`
            : moduleName || "/pricing-plans"
        }
      />
    );
  }
  document.title = "Sign In";
  return (
    <AuthWrapper style={{ height: "100%", overflow: "auto" }}>
      <div className="row" style={{ height: "100%", marginRight: "0px" }}>
        <div
          id="div1"
          style={{ background: "#eff3fe", overflow: "auto" }}
          className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6  flex-column d-flex"
        >
          <div className="d-flex flex-row-fluid flex-column text-center p-10">
            <span className="cursor-pointer py-9 mb-5 mt-20 d-flex justify-content-center">
              <img
                alt="Logo"
                src="/media/logo-main.png"
                loading="lazy"
                className="h-70px  shimmer"
                onError={(e) => {
                  e.target.src =
                    "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png";
                }}
              />
            </span>
            <h1
              className="fs-2qx pb-5 pb-md-4 fw-normal"
              style={{ color: "#494951" }}
            >
              Welcome to <b className="fw-boldest">Asinwiser</b>
            </h1>
            <p className="fw-normal fs-3" style={{ color: "#494951" }}>
              An Intelligent tool for Amazon Sellers
            </p>
          </div>
          <div className="authFadeInTop d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px">
            <img
              src="/media/login-image.png"
              alt="login-image.png"
              loading="lazy"
              className="mx-auto"
              style={{ width: "90%" }}
              onError={(e) => {
                e.target.src =
                  "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png";
              }}
            />
          </div>
        </div>
        <div
          id="div2 "
          style={{ background: "#fff", height: "auto", overflow: "auto" }}
          className=" authFadeInBottom d-flex flex-column col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
        >
          <div className="d-flex flex-column flex-lg-row-fluid py-10">
            <div className="d-flex flex-center flex-column flex-column-fluid">
              <div className="w-lg-500px  mx-auto animated">
                <form
                  className="form w-100"
                  noValidate="novalidate"
                  id="kt_sign_in_form"
                >
                  <div className="text-center mb-10">
                    <h1 className="text-dark fw-bold mb-3">
                      Sign In to <b className="fw-boldest">Asinwiser</b>
                    </h1>
                    <div className="text-gray-400 fw-bold fs-4">
                      New Here?{" "}
                      <Link
                        to="/create-account"
                        className="link-primary fw-bolder"
                      >
                        Create an Account
                      </Link>
                    </div>
                  </div>
                  <div className="fv-row mb-10">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Email
                    </label>
                    <Input
                      placeholder="Enter Email"
                      type="email"
                      size="large"
                      autoFocus
                      ref={focusFirst}
                      value={username}
                      autoComplete="new-email"
                      onChange={(e) => setUserName(e.target.value)}
                      disabled={sending}
                    />
                  </div>
                  <div className="fv-row mb-10">
                    <div className="d-flex flex-stack mb-2">
                      <label className="form-label fw-bolder text-dark fs-6 mb-0">
                        Password
                      </label>
                      <ASINLink
                        id={Math.random()}
                        to={{ pathname: "/forgot-password" }}
                        className="link-primary fs-6 fw-bolder"
                      >
                        Forgot Password ?
                      </ASINLink>
                    </div>
                    <Input.Password
                      placeholder="Enter Password"
                      size="large"
                      autoComplete="new-email"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={sending}
                    />
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      disabled={username && password && !sending ? false : true}
                      className="btn btn-lg btn-primary w-100 mb-5"
                      onClick={onLogin}
                    >
                      {sending ? (
                        <span>
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      ) : (
                        <span className="indicator-label">Login</span>
                      )}
                    </button>
                  </div>
                  <div className="text-center">
                    <div className="mt-20 d-flex justify-content-center">
                      <label style={{ fontSize: "20px" }}>
                        <span className="me-2">
                          If you want to use Reimbursement Tool
                        </span>{" "}
                        <a
                          style={{
                            color: "#4a41eb",
                            borderBottom: "2px solid #4a41eb",
                            cursor: "pointer",
                          }}
                          href="https://portal.rightfully.com/accountsApp/register/?partner=2657e6d7-c531-494a-b8c0-cba440bd7874"
                          target="_blank"
                        >
                          Click Here
                        </a>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
              <div className="d-flex flex-center fw-bold fs-6">
                <Link className="text-muted text-hover-primary px-2">
                  About
                </Link>
                <Link className="text-muted text-hover-primary px-2">
                  Support
                </Link>
                <Link className="text-muted text-hover-primary px-2">
                  Purchase
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
  // return (
  //   <AuthWrapper style={{ height: '100%', overflow: 'auto' }}>
  //     <div className="d-flex flex-column flex-root" style={{ height: '100%' }}>
  //       <div className="d-flex flex-column flex-lg-row  flex-column-fluid">
  //         <div
  //           className="d-flex flex-column flex-lg-row-auto w-xl-700px positon-xl-relative"
  //           style={{ backgroundColor: "#eff3fe" }}
  //         >
  //           <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-700px scroll-y hideScroll">
  //             <div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
  //               <span className="cursor-pointer py-9 mb-5">
  //                 <img
  //                   alt="Logo"
  //                   src="/media/logo-main.png"
  //                   className="h-70px"
  //                 />
  //               </span>
  //               <h1
  //                 className="fs-2qx pb-5 pb-md-4 fw-normal"
  //                 style={{ color: "#494951" }}
  //               >
  //                 Welcome to <b className="fw-boldest">Asinwiser</b>
  //               </h1>
  //               <p className="fw-normal fs-3" style={{ color: "#494951" }}>
  //                 An Intelligent tool for Amazon Sellers
  //               </p>
  //             </div>
  //             <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px">
  //               <img
  //                 src="/media/login-image.png"
  //                 alt="login-image.png"
  //                 className="mx-auto"
  //                 style={{ width: "90%" }}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //         <div className="d-flex flex-column flex-lg-row-fluid py-10">
  //           <div className="d-flex flex-center flex-column flex-column-fluid">
  //             <div className="w-lg-500px p-10 p-lg-15 mx-auto">
  //               <form
  //                 className="form w-100"
  //                 noValidate="novalidate"
  //                 id="kt_sign_in_form"
  //               >
  //                 <div className="text-center mb-10">
  //                   <h1 className="text-dark fw-bold mb-3">
  //                     Sign In to <b className="fw-boldest">Asinwiser</b>
  //                   </h1>
  //                   <div className="text-gray-400 fw-bold fs-4">
  //                     New Here?{" "}
  //                     <Link
  //                       to="/create-account"
  //                       className="link-primary fw-bolder"
  //                     >
  //                       Create an Account
  //                     </Link>
  //                   </div>
  //                 </div>
  //                 <div className="fv-row mb-10">
  //                   <label className="form-label fs-6 fw-bolder text-dark">
  //                     Email
  //                   </label>
  //                   <Input
  //                     placeholder="Enter Email"
  //                     type="email"
  //                     size="large"
  //                     value={username}
  //                     style={{ backgroundColor: "#e8f0fe" }}
  //                     onChange={(e) => setUserName(e.target.value)}
  //                   />
  //                 </div>
  //                 <div className="fv-row mb-10">
  //                   <div className="d-flex flex-stack mb-2">
  //                     <label className="form-label fw-bolder text-dark fs-6 mb-0">
  //                       Password
  //                     </label>
  //                     <ASINLink
  //                       id={Math.random()}
  //                       to={{ pathname: "/forgot-password" }}
  //                       className="link-primary fs-6 fw-bolder"
  //                     >
  //                       Forgot Password ?
  //                     </ASINLink>
  //                   </div>
  //                   <Input.Password
  //                     placeholder="Enter Password"
  //                     size="large"
  //                     value={password}
  //                     onChange={(e) => setPassword(e.target.value)}
  //                   />
  //                 </div>
  //                 <div className="text-center">
  //                   <button
  //                     type="submit"
  //                     disabled={username && password && !sending ? false : true}
  //                     className="btn btn-lg btn-primary w-100 mb-5"
  //                     onClick={onLogin}
  //                   >
  //                     {sending ? (
  //                       <span>
  //                         Please wait...
  //                         <span className="spinner-border spinner-border-sm align-middle ms-2" />
  //                       </span>
  //                     ) : (
  //                       <span className="indicator-label">Login</span>
  //                     )}
  //                   </button>
  //                 </div>
  //               </form>
  //             </div>
  //           </div>
  //           <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
  //             <div className="d-flex flex-center fw-bold fs-6">
  //               <Link className="text-muted text-hover-primary px-2">
  //                 About
  //               </Link>
  //               <Link className="text-muted text-hover-primary px-2">
  //                 Support
  //               </Link>
  //               <Link className="text-muted text-hover-primary px-2">
  //                 Purchase
  //               </Link>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </AuthWrapper>
  // );
}

export default Login;
