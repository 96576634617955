export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_SUPPLIER_SETUP_TABLE_LIST_SUCCESS":
    case "GET_SUPPLIER_SETUP_TABLE_LIST_ERROR":
      return {
        ...state,
        GetSupplierListResponse: action.updatePayload,
      };

    case "CREATE_SUPPLIER_SETUP_SUCCESS":
    case "CREATE_SUPPLIER_SETUP_ERROR":
      return {
        ...state,
        CreateSupplierResponse: action.updatePayload,
      };

    case "DELETE_SUPPLIER_SETUP_SUCCESS":
    case "DELETE_SUPPLIER_SETUP_ERROR":
      return {
        ...state,
        DeleteSupplierResponse: action.updatePayload,
      };

    case "FAKE_ACTION_SUPPLIER_SETUP":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
