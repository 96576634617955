import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserActive from "../../../modules/pages/auth/user-active";
import { userActiveAction, fakeActionAuth } from '../../../redux/modules/auth/auth.action';

const mapStateToProps = (state) => ({
  UserActiveResponse: state.Auth.UserActiveResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userActiveAction,
      fakeActionAuth,
    },
    dispatch
  );

const UserActiveASINWiser = connect(mapStateToProps, mapDispatchToProps)(UserActive);

export default UserActiveASINWiser;
