import Icons from "../components/icons";
import { convertSidebarLinks } from "../core/helper/static/sidebar";
export const ENDPOINT = "https://api2.asinwiser.com/";
export const API_URL = `${ENDPOINT}api/v1/`;
export const KeepaGraph = "https://graph.keepa.com/pricehistory.png";
export const AmazonawsList =
  "https://urrmjlumse.execute-api.eu-west-2.amazonaws.com/prod/api/";
export const AppId = "amzn1.sp.solution.e2a36e30-a33c-49c3-9d8d-38536022cb5f";
export const client_id =
  "amzn1.application-oa2-client.994a18387b484071a77ea05044da411f";
export const CallBackAds = "https://dev.asinwiser.com/callbackads";
// export const planToken = "pk_test_FgJ2NUlni0eehEtRDcLOdXvx00NmYpDbHt";
export const planToken =
  "pk_live_51JyUJ8ICBBYpbf3f4WlkvI7QJcBYIWTU9rcFDntjTZJDTJBGdXQ642AGm8fyn4yPRNAgj7B2DMplSMLY4wCMYGGu00tBOCjSGF";
export const pageDropdown = [10, 25, 50, 100];
export const DefaultPerPage = 10;
export const DefaultKeywordPerPage = 20;
export const DateFormat = "MMM DD, YYYY";
export const priceCommonSign = "£";
export const priceSign = {
  "United States(Amazon.com)": "$",
  "US(Amazon.com)": "$",
  USA: "$",
  "UK(Amazon.co.uk)": "£",
  "United Kingdom": "£",
  "Germany(Amazon.de)": "€",
  Germany: "€",
  "France(Amazon.fr)": "€",
  France: "€",
  "Canada(Amazon.ca)": "$",
  "Italy(Amazon.it)": "€",
  Italy: "€",
  "Spain(Amazon.es)": "€",
  Spain: "€",
  "India(Amazon.in)": "₹",
  "Mexico(Amazon.com.mx)": "$",
  Brazil: "$",
  Canada: "$",
  // Mexico: ,
  // 'United Arab Emirates (U.A.E.)': ,
  // India: ,
  // Netherlands: ,
  // 'Saudi Arabia': ,
  // Turkey: ,
  // Singapore: ,
  // Australia: ,
  // Japan: ,
};

export const clearLocalData = () => {
  localStorage.clear();
  window.location.assign("/login");
  window.location.reload();
};

export const handleStorageChange = (e) => {
  e.stopPropagation();
  if (e.url === window.location.href) {
    if (!e.key) {
      clearLocalData();
    } else {
      localStorage.setItem(e.key, e.oldValue);
    }
  }
  if (
    !localStorage.getItem("token") ||
    !localStorage.getItem("user") ||
    !localStorage.getItem("userType") ||
    !localStorage.getItem("menusList")
  ) {
    clearLocalData();
  }
};

export const NumberWithCommas = (n) => {
  const num_ = parseFloat(n || 0);
  if (num_) {
    if (Number(num_) === num_ && num_ % 1 !== 0) {
      return parseFloat(num_)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return parseInt(num_)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return num_;
};

export const marketplaceVAT = {
  A1F83G8C2ARO7P: 20, // UK
  // A1F83G8C2AROUS: 5,
  A1PA6795UKMFR9: 19, // Germony
  A13V1IB3VIYZZH: 21, // France
  A1RKKUPIHCS9HS: 21, // Spain
  APJ6JRA9NG5V4: 22,
};

export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date * 1000) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};

export const urlDecode = (url) => {
  let newObj = {};
  url?.search
    ?.split("?")[1]
    ?.split("&")
    ?.forEach((e) => {
      const obj = e?.split("=");
      newObj = { ...newObj, [obj[0]]: obj[1] };
    });
  return newObj || {};
};

// US - $
// Mexico - $
// Canada - $

// Germony - €
// France -  €
// Italy - €
// Spain - €

// Uk -  £

// India -  ₹

// New Code
export const columnCommFunction = (
  isChange,
  Sign,
  marketplaceSign,
  marketplaceName,
  key1,
  key2,
  withDecimal
) => {
  {
    if (!key1 && !key2) {
      return (
        <span>
          {Sign === "currency" ? marketplaceSign : ""}
          {Sign === "has" ? "#" : ""} 0 {Sign === "percent" ? "%" : ""}
        </span>
      );
    } else if (!isChange) {
      return (
        <span>
          {Sign === "currency" ? marketplaceSign : ""}
          {Sign === "has" ? "#" : ""} {key1 || key2}
          {Sign === "percent" ? "%" : ""}
        </span>
      );
    } else if (
      typeof key1 === "number" ||
      key2 === "number" ||
      !isNaN(parseInt(key1 || key2))
    ) {
      if (withDecimal) {
        let number = key1 || key2 || 0;
        const decimalValue = number / 100;
        return (
          <span>
            {Sign === "currency" ? marketplaceSign : ""}
            {Sign === "has" ? "#" : ""}
            &nbsp;
            {decimalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            {Sign === "percent" ? "%" : ""}
          </span>
        );
      } else if (!withDecimal) {
        return (
          <span>
            {Sign === "currency" ? marketplaceSign : ""}
            {Sign === "has" ? "#" : ""}
            &nbsp;
            {(key1 || key2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            {Sign === "percent" ? "%" : ""}
          </span>
        );
      }
    } else {
      return <span>{key1 || key2}</span>;
    }
  }
};

export const testArr = [
  "dashboard",
  "item-master",
  "keyword-tracker",
  "listing-optimizer",
  "inventory",
  "master-table",
  "inventory-management",
  "sp-api-reports",
  "repricing",
  "inventory-forecasting",
  "data-scheduler",
  "competitor-research",
  "profitability-calculator",
  "bulk-profitability-calculator",
  "sales-analytics",
  "manage-company-employee",
  "wholesale-supplier-directory",
  "wholesale-supplier-list",
  "product-research",
  "competitor-search-analytics",
  "pricing-plans",
  "payment-history",
  "feedback-automation",
  // "profile",
  "reimbursement",
  "brand-wise-analytics",
  "my-list",
  "setting",
  "application-logs",
  // "algorithmic-repricer",
];

export const defaultMenus = ["pricing-plans", "setting"];
export const adminMenus = [
  "manage-seller-users",
  "estimated-sales",
  "wholesale-supplier-directory",
  "categories",
  "application-logs",
  "user-feedback",
  "manage-subscription",
  "wholesale-supplier-list",
  "setting",
];

const bullet = (
  <span className="menu-bullet">
    <span className="bullet bullet-dot" />
  </span>
);

const GetIcons = (icon) => {
  return <Icons type={icon} />;
};

const appLogs = [
  {
    title: "Central log system",
    name: "central-log-system",
    path: ["/central-log-system"],
    index: [],
    parent: "application-logs",
    icon: bullet,
  },
  {
    title: "System event log",
    name: "system-event-log",
    path: ["/system-event-log"],
    index: [],
    parent: "application-logs",
    icon: bullet,
  },
];

const Modules = [
  {
    title: "Manage Seller Users",
    name: "manage-seller-users",
    path: ["/manage-seller-users"],
    index: [
      {
        admin: 1,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("users"),
  },
  {
    title: "Estimated Sales",
    name: "estimated-sales",
    path: ["/estimated-sales"],
    index: [
      {
        admin: 2,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("estimated-sales"),
  },
  {
    title: "Categories",
    name: "categories",
    path: ["/categories"],
    index: [
      {
        admin: 3,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("categories"),
  },
  {
    title: "Wholesale Supplier Directory",
    name: "wholesale-supplier-directory",
    path: [],
    index: [
      {
        admin: 4,
        common: 4,
      },
    ],
    rule: ["admin", "common"],
    icon: GetIcons("wholesale-supplier"),
  },
  {
    title: "Manage Subscription",
    name: "manage-subscription",
    path: ["/manage-subscription"],
    index: [
      {
        admin: 5,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("pricing"),
  },
  {
    title: "Brand Rules",
    name: "brand-rules",
    path: ["/brand-rules"],
    index: [
      {
        admin: 6,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("pricing"),
  },
  {
    title: "User Feedback",
    name: "user-feedback",
    path: ["/user-feedback"],
    index: [
      {
        admin: 7,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: (
      <i className="bi bi-chat-right-quote-fill" style={{ color: "#454545" }} />
    ),
  },
  {
    title: "Setting",
    name: "setting",
    parentPath: "/setting",
    path: [],
    index: [
      {
        admin: 8,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("setting"),
    children: [
      {
        title: "App Configuration",
        name: "app-configuration",
        path: ["/app-configuration"],
        index: [],
        parent: "setting",
        icon: bullet,
      },
      {
        title: "Admin Scheduler",
        name: "admin-scheduler",
        path: ["/admin-scheduler"],
        index: [],
        parent: "setting",
        icon: bullet,
      },
    ],
  },
  // admin end
  // user start
  {
    title: "Dashboard",
    name: "dashboard",
    path: ["/", "/dashboard"],
    index: [
      {
        admin: -1,
        common: 1,
      },
    ],
    rule: ["common"],
    icon: GetIcons("dashboard"),
  },
  {
    title: "Product Research",
    name: "product-research",
    path: ["/product-research"],
    index: [
      {
        admin: -1,
        common: 2,
      },
    ],
    rule: ["common"],
    icon: GetIcons("product-research"),
  },
  {
    title: "Feedback Automation",
    name: "feedback-automation",
    path: ["/feedback-automation"],
    index: [
      {
        admin: -1,
        common: 10,
      },
    ],
    rule: ["common"],
    icon: GetIcons("product-research"),
  },
  // {
  //   title: "Expenses",
  //   name: "expenses",
  //   path: ["/expenses"],
  //   index: [
  //     {
  //       admin: -1,
  //       common: 20,
  //     },
  //   ],
  //   rule: ["common"],
  //   icon: GetIcons("product-research"),
  // },
  {
    title: "Details",
    name: "details",
    path: ["/product-research/details"],
    parentName: "Product Research",
    parentPath: "/product-research",
    index: [],
    rule: [],
    icon: null,
  },
  {
    title: "Competitor Research",
    name: "competitor-research",
    path: ["/competitor-research"],
    index: [
      {
        admin: -1,
        common: 3,
      },
    ],
    rule: ["common"],
    icon: GetIcons("competitor-research"),
  },
  {
    title: "Listing Optimizer",
    name: "listing-optimizer",
    path: ["/listing-optimizer"],
    index: [
      {
        admin: -1,
        common: 5,
      },
    ],
    rule: ["common"],
    icon: GetIcons("keyword-tracker"),
  },
  {
    title: "Master Table",
    name: "master-table",
    path: [],
    parentPath: "/master-table",
    index: [
      {
        admin: -1,
        common: 6,
      },
    ],
    rule: ["common"],
    icon: GetIcons("menuSideBar"),
    children: [
      {
        title: "Brands",
        name: "brands",
        path: ["/brands"],
        index: [],
        parent: "master-table",
        icon: bullet,
      },
      {
        title: "Sku Data",
        name: "sku-data",
        path: ["/sku-data"],
        index: [],
        parent: "master-table",
        icon: bullet,
      },
      {
        title: "Expenses",
        name: "expenses",
        path: ["/expenses"],
        index: [],
        parent: "master-table",
        icon: bullet,
      },
      {
        title: "Supplier Setup",
        name: "supplier-setup",
        path: ["/supplier-setup"],
        index: [],
        parent: "master-table",
        icon: bullet,
      },
    ],
  },
  {
    title: "Repricing",
    name: "repricing",
    path: [],
    parentPath: "/repricing",
    index: [{ admin: -1, common: 7 }],
    rule: ["common"],
    icon: GetIcons("layers"),
    children: [
      {
        title: "Products",
        name: "products",
        path: ["/products"],
        index: [],
        parent: "repricing",
        icon: bullet,
      },
      {
        title: "Amazon Repricing Rules",
        name: "amazon-repricing-rules",
        path: ["/amazon-repricing-rules"],
        index: [],
        parent: "repricing",
        icon: bullet,
      },
      // {
      //   title: "Repricing Product",
      //   name: "repricing-product",
      //   path: ["/repricing-product"],
      //   index: [],
      //   parent: "repricing",
      //   icon: bullet,
      // },
      {
        title: "Advanced Repricing Rules",
        name: "advanced-repricing-rules",
        path: ["/advanced-repricing-rules"],
        index: [],
        parent: "repricing",
        icon: bullet,
      },
    ],
  },
  {
    title: "Add Rules",
    name: "sales-analytics",
    path: ["/repricing/amazon-repricing-rules/add-rules"],
    parentName: "Amazon Repricing Rules",
    parentPath: "/repricing/amazon-repricing-rules",
    index: [],
    rule: [],
    icon: null,
  },
  {
    title: "Sales Analytics",
    name: "sales-analytics",
    path: ["/sales-analytics"],
    index: [
      {
        admin: -1,
        common: 8,
      },
    ],
    rule: ["common"],
    icon: GetIcons("product-research"),
  },
  {
    title: "SP API Reports",
    name: "sp-api-reports",
    path: ["/sp-api-reports"],
    index: [
      {
        admin: -1,
        common: 9,
      },
    ],
    rule: ["common"],
    icon: GetIcons("competitor-research"),
  },
  {
    title: "Reimburs...(In Progress)",
    name: "reimbursement",
    path: ["/reimbursement"],
    index: [
      {
        admin: -1,
        common: 11,
      },
    ],
    rule: ["common"],
    icon: GetIcons("competitor-research"),
  },
  // {
  //   title: "Sp Api Reports",
  //   name: "sp-api-reports",
  //   parentPath: "/sp-api-reports",
  //   path: [],
  //   index: [
  //     {
  //       admin: -1,
  //       common: 9,
  //     },
  //   ],
  //   rule: ["common"],
  //   icon: GetIcons("competitor-research"),
  //   children: [
  //     {
  //       title: "Orders Data",
  //       name: "orders-data",
  //       path: ["/orders-data"],
  //       index: [],
  //       parent: "sp-api-reports",
  //       icon: bullet,
  //     },
  //     {
  //       title: "Return Order Data",
  //       name: "return-order-data",
  //       path: ["/return-order-data"],
  //       index: [],
  //       parent: "sp-api-reports",
  //       icon: bullet,
  //     },

  //     {
  //       title: "Archived Inventory",
  //       name: "archived-inventory",
  //       path: ["/archived-inventory"],
  //       index: [],
  //       parent: "sp-api-reports",
  //       icon: bullet,
  //     },
  //     {
  //       title: "Reserved Inventory",
  //       name: "reserved-inventory",
  //       path: ["/reserved-inventory"],
  //       index: [],
  //       parent: "sp-api-reports",
  //       icon: bullet,
  //     },
  //     {
  //       title: "Planning Inventory",
  //       name: "planning-inventory",
  //       path: ["/planning-inventory"],
  //       index: [],
  //       parent: "sp-api-reports",
  //       icon: bullet,
  //     },
  //     {
  //       title: "Stranded Inventory",
  //       name: "stranded-inventory",
  //       path: ["/stranded-inventory"],
  //       index: [],
  //       parent: "sp-api-reports",
  //       icon: bullet,
  //     },
  //     {
  //       title: "Finance Data",
  //       name: "finance-data",
  //       path: ["/finance-data"],
  //       index: [],
  //       parent: "sp-api-reports",
  //       icon: bullet,
  //     },
  //     {
  //       title: "Product Data",
  //       name: "product-data",
  //       path: ["/product-data"],
  //       index: [],
  //       parent: "sp-api-reports",
  //       icon: bullet,
  //     },
  //     {
  //       title: "Reimbursements",
  //       name: "reimbursements",
  //       path: ["/reimbursements"],
  //       index: [],
  //       parent: "sp-api-reports",
  //       icon: bullet,
  //     },
  //   ],
  // },
  {
    title: "Inventory",
    name: "inventory",
    path: ["/inventory"],
    index: [
      {
        admin: -1,
        common: 12,
      },
    ],
    rule: ["common"],
    icon: GetIcons("wholesale-supplier"),
    children: [
      {
        title: "Replenishment",
        name: "replenishment",
        path: ["/replenishment"],
        index: [],
        parent: "inventory",
        icon: bullet,
      },
      {
        title: "Reconciliation",
        name: "reconciliation",
        path: ["/reconciliation"],
        index: [],
        parent: "inventory",
        icon: bullet,
      },
      {
        title: "PO List",
        name: "polist",
        path: ["/polist"],
        index: [],
        parent: "inventory",
        icon: bullet,
      },
    ],
  },
  {
    title: "ASIN Details",
    name: "asin-details",
    path: ["/asin-details"],
    parentName: "",
    parentPath: "",
    index: [],
    rule: [],
    icon: null,
  },
  {
    title: "Profitability Calculator",
    name: "profitability-calculator",
    path: ["/profitability-calculator"],
    index: [
      {
        admin: -1,
        common: 13,
      },
    ],
    rule: ["common"],
    icon: GetIcons("profitability-calculator"),
  },
  {
    title: "Bulk Profitability Calculator",
    name: "bulk-profitability-calculator",
    path: ["/bulk-profitability-calculator"],
    index: [
      {
        admin: -1,
        common: 14,
      },
    ],
    rule: ["common"],
    icon: GetIcons("bulk-profitability-calculator"),
  },
  {
    title: "Details",
    name: "details",
    path: ["/bulk-profitability-calculator/details"],
    parentName: "Bulk Profitability Calculator",
    parentPath: "/bulk-profitability-calculator",
    index: [],
    rule: [],
    icon: null,
  },
  {
    title: "Manage Company Employee",
    name: "manage-company-employee",
    path: ["/manage-company-employee"],
    index: [
      {
        admin: -1,
        common: 15,
      },
    ],
    rule: ["common"],
    icon: GetIcons("manage-seller-user"),
  },
  {
    title: "Pricing Plans",
    name: "pricing-plans",
    path: ["/pricing-plans"],
    index: [
      {
        admin: -1,
        common: 16,
      },
    ],
    rule: ["common", "default"],
    icon: GetIcons("pricing"),
  },
  {
    title: "Payment History",
    name: "payment-history",
    path: ["/payment-history"],
    index: [
      {
        admin: -1,
        common: 17,
      },
    ],
    rule: ["common"],
    icon: GetIcons("payment-history"),
  },
  // {
  //   title: "Profile",
  //   name: "profile",
  //   path: ["/profile"],
  //   index: [
  //     {
  //       admin: -1,
  //       common: 16,
  //     },
  //   ],
  //   rule: ["common", "default"],
  //   icon: GetIcons("profile"),
  // },
  {
    title: "Setting",
    name: "setting",
    path: ["/setting"],
    index: [
      {
        admin: -1,
        common: 18,
      },
    ],
    rule: ["common", "default"],
    icon: GetIcons("setting"),
  },
  {
    title: "Amazon SP API Credentials",
    name: "amazon-sp-api-credentials",
    path: ["/spapi-callback"],
    parentName: "Setting",
    parentPath: "/setting",
    index: [],
    rule: [],
    icon: null,
  },
  {
    title: "Advertising Credentials",
    name: "callbackads",
    path: ["/callbackads"],
    index: [],
    parentName: "Setting",
    parentPath: "/setting",
    rule: [],
    icon: null,
  },
  {
    title: "Data Scheduler",
    name: "data-scheduler",
    path: ["/data-scheduler"],
    index: [
      {
        admin: -1,
        common: 19,
      },
    ],
    rule: ["common"],
    icon: GetIcons("data-scheduler"),
  },
  {
    title: "Wholesale Suppliers Directory",
    name: "wholesale-supplier-list",
    path: ["/wholesale-supplier-directory"],
    index: [],
    rule: [],
    icon: null,
  },
  {
    title: "List",
    name: "list",
    path: ["/wholesale-supplier-list"],
    parentName: "Wholesale Suppliers Directory",
    parentPath: "/wholesale-supplier-directory",
    index: [],
    rule: [],
    icon: null,
  },
  {
    title: "Keyword Tracker",
    name: "keyword-tracker",
    path: ["/listing-optimizer/keyword-tracker"],
    parentName: "Listing Optimizer",
    parentPath: "/listing-optimizer",
    index: [],
    rule: [],
    icon: null,
  },
  // {
  //   title: "Inventory Management",
  //   name: "inventory-management",
  //   path: ["/inventory-management"],
  //   index: [],
  //   rule: ["common"],
  //   icon: null,
  // },
  // {
  //   title: "Inventory Forecasting",
  //   name: "inventory-forecasting",
  //   path: ["/inventory-forecasting"],
  //   index: [
  //     {
  //       admin: 1,
  //       user: 0,
  //     },
  //   ],
  //   rule: ["common"],
  //   icon: null,
  // },
  {
    title: "Analytics By Brand",
    name: "competitor-search-analytics",
    path: ["/competitor-search-analytics/analytics-by-brand"],
    parentName: "Competitor Search Analytics",
    parentPath: "/competitor-search-analytics",
    index: [],
    rule: [],
    icon: null,
  },
  {
    title: "Brand Wise Analytics",
    name: "brand-wise-analytics",
    path: ["/brand-wise-analytics"],
    parentName: "Product Research",
    parentPath: "/product-research",
    index: [],
    rule: [],
    icon: null,
  },
  {
    title: "My List",
    name: "my-list",
    path: ["/my-list"],
    index: [],
    rule: [],
    icon: null,
  },
  // user end
  // user and admin
  {
    title: "Application logs",
    name: "application-logs",
    parentPath: "/application-logs",
    path: [],
    index: [
      {
        admin: 9,
        common: 20,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("admin-menu"),
    children: appLogs,
  },
  {
    title: "Application logs",
    name: "application-logs",
    parentPath: "/application-logs",
    path: [],
    index: [
      {
        admin: 9,
        common: 20,
      },
    ],
    rule: ["common"],
    icon: GetIcons("admin-menu"),
    children: [
      {
        title: "SQS Messages",
        name: "sqs-messages",
        path: ["/sqs-messages"],
        index: [],
        parent: "application-logs",
        icon: bullet,
      },
      {
        title: "Repricing Notification",
        name: "repricing-notification",
        path: ["/repricing-notification"],
        index: [],
        parent: "application-logs",
        icon: bullet,
      },
      {
        title: "Central log system",
        name: "central-log-system",
        path: ["/central-log-system"],
        index: [],
        parent: "application-logs",
        icon: bullet,
      },
      {
        title: "System event log",
        name: "system-event-log",
        path: ["/system-event-log"],
        index: [],
        parent: "application-logs",
        icon: bullet,
      },
    ],
  },
  {
    title: "Algorithmic Repricer",
    name: "algorithmic-repricer",
    path: [],
    parentPath: "/algorithmic-repricer",
    index: [{ admin: -1, common: 21 }],
    rule: ["common"],
    icon: GetIcons("layers"),
    children: [
      {
        title: "Product Repricing",
        name: "product-repricing",
        path: ["/product-repricing"],
        index: [],
        parent: "algorithmic-repricer",
        icon: bullet,
      },
      {
        title: "Repricing Rules",
        name: "repricing-rules",
        path: ["/repricing-rules"],
        index: [],
        parent: "algorithmic-repricer",
        icon: bullet,
      },
    ],
  },
];

export const MyNewSidebarFunc = (userMenus, mode) => {
  if (mode === "admin") {
    return userMenus;
  }
  const menuList = localStorage.getItem("menusList")
    ? JSON.parse(localStorage.getItem("menusList")) || []
    : [];
  let menus = [];
  userMenus.forEach((d) => {
    const index = menuList?.findIndex((a) => a === d.key);
    if (index !== -1) {
      menus.push(d);
    }
  });
  return menus;
};

export const ModulesList = (props) => {
  const { type, mode } = props;
  if (type === "sidebar") {
    return MyNewSidebarFunc(
      convertSidebarLinks(
        Modules?.filter((d) => d?.rule?.findIndex((t) => t === mode) !== -1),
        mode
      ),
      mode
    );
  } else if (type === "url") {
    let obj = {};
    Modules.forEach((r) => {
      r.path.forEach((p) => {
        obj = {
          ...obj,
          [p]: {
            name: r.title,
            parent: r.parentName,
            path: r.parentPath,
          },
        };
      });
      r?.children?.forEach((p) => {
        p.path.forEach((i) => {
          obj = {
            ...obj,
            [i]: {
              name: p?.title,
              path: r?.parentPath + i,
              parent: r?.title,
            },
          };
        });
      });
    });
    return obj;
  }
  return [];
};

const downloadFile = (href, name) => {
  const link = document.createElement("a");
  link.href = `${href}${name}`;
  link.setAttribute("download", name);
  link.click();
  link.remove();
};

const findBySKUFile = (localVat) => {
  const { vat_type, vat_on_cost, vat_on_sale } = localVat;
  if (vat_type === "standard_rate") {
    if (vat_on_cost == 1 && vat_on_sale == 1) {
      return "SKUDataSampleTemplate1.xlsx";
    } else if (vat_on_cost == 1 && vat_on_sale == 0) {
      return "SKUDataCost1Sale0.xlsx";
    } else if (vat_on_cost == 0 && vat_on_sale == 1) {
      return "SKUDataCost0Sale1.xlsx";
    } else {
      return "SKUDataCost0Sale0.xlsx";
    }
  } else if (vat_type === "flat_rate") {
    return "SKUDataFlatRate.xlsx";
  } else if (vat_type === "vat_exempt") {
    return "SKUDataVatExempt0.xlsx";
  }
  return "SKUDataNotRegistered.xlsx";
};

export const skuDataSampleExport = (localVat) => {
  const prefix = "/media/skuDataExport/";
  downloadFile(prefix, findBySKUFile(localVat));
};

export const WarehouseSampleTemplate = () => {
  return downloadFile("/media/", "warehouse_stock_sample.xlsx");
};

export const bulkProfitSampleExport = (localVat) => {
  // default
  return downloadFile(
    "/media/bulkProfitExport/",
    "BulkProfitSampleTemplate.xlsx"
  ); // changes by fenali
  const vatType = localVat?.vat_type;
  const vatOnCost = localVat?.vat_on_cost;
  const vatOnSale = localVat?.vat_on_sale;

  if (vatType === "standard_rate") {
    if (vatOnCost == 1 && vatOnSale == 1) {
      const link = document.createElement("a");
      link.href = "/media/bulkProfitExport/BulkCalculatorSampleTemplate1.xlsx";
      link.setAttribute("download", "BulkCalculatorSampleTemplate1.xlsx");
      link.click();
    } else if (vatOnCost == 1 && vatOnSale == 0) {
      const link = document.createElement("a");
      link.href = "/media/bulkProfitExport/BulkCalculatorCost1Sale0.xlsx";
      link.setAttribute("download", "BulkCalculatorCost1Sale0.xlsx");
      link.click();
    } else if (vatOnCost == 0 && vatOnSale == 1) {
      const link = document.createElement("a");
      link.href = "/media/bulkProfitExport/BulkCalculatorCost0Sale1.xlsx";
      link.setAttribute("download", "BulkCalculatorCost0Sale1.xlsx");
      link.click();
    } else {
      const link = document.createElement("a");
      link.href = "/media/bulkProfitExport/BulkCalculatorCost0Sale0.xlsx";
      link.setAttribute("download", "BulkCalculatorCost0Sale0.xlsx");
      link.click();
    }
  } else if (vatType === "flat_rate") {
    const link = document.createElement("a");
    link.href = "/media/bulkProfitExport/BulkCalculatorFlatRate.xlsx";
    link.setAttribute("download", "BulkCalculatorFlatRate.xlsx");
    link.click();
  } else {
    const link = document.createElement("a");
    link.href = "/media/bulkProfitExport/BulkCalculatorNotRegistered.xlsx";
    link.setAttribute("download", "BulkCalculatorNotRegistered.xlsx");
    link.click();
  }
};

export const vatConfig = () => {
  const { vat, vat_on_cost, vat_on_sale, vat_type, vat_on_rate } = JSON.parse(
    localStorage.getItem("user")
  );
  return { vat, vat_on_cost, vat_on_sale, vat_type, vat_on_rate };
};

const getIds = (type, marketplaceList, d, getType, labelType) => {
  const marketplace = marketplaceList?.[d];

  return type === "number" // for domain
    ? getType === "values"
      ? d
      : marketplace
    : getType === "label"
    ? labelType === "sales_channel" // for sales channel
      ? marketplace?.marketplace_id === null
        ? marketplace?.sales_channel
        : `${marketplace?.marketplace}(${marketplace?.sales_channel})` // for sales channel
      : `${marketplace?.marketplace_name}(${marketplace?.sales_channel})` // for sales marketplace by credential
    : marketplace?.marketplace_id || marketplace?.sales_channel; // for value of marketplace by cred and sales channel
};

export const MarketplaceFlag = (
  marketplaceList,
  type = "number",
  labelType = "default"
) =>
  Object.keys(marketplaceList || {}).map((d, i) => {
    const obj_ = type === "number" ? d : marketplaceList?.[d];
    return {
      value: getIds(type, marketplaceList, d, "values", labelType),
      label: (
        <>
          <img
            src={`/media/domainImage/${getIds(
              type,
              marketplaceList,
              d,
              "image",
              labelType
            )}.png`}
            style={{ height: "20px" }}
            loading="lazy"
            className="me-3"
            onError={(e) => {
              e.target.src = "/media/domainImage/red-flag.png";
            }}
          />
          {getIds(type, marketplaceList, d, "label", labelType)}
        </>
      ),
      ...obj_,
    };
  });

export const setValueColor = () => {
  let numbers = document.querySelectorAll(".CurrencyVal");
  console.log(numbers, "value");
  numbers.forEach(function (element) {
    // console.log(
    //   element.getElementsByClassName("value")?.[0]?.textContent,
    //   element
    // );
    let value = element.getElementsByClassName("value")?.[0]?.textContent;

    element.style.color = value > 0 ? "green" : value == 0 ? "" : "red";
    // element.style.color = value === 0 ? "black" : value < 0 ? "red" : "green";
  });
};
