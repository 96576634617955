export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_COLUMN_CONFIGURATION_SUCCESS":
    case "GET_COLUMN_CONFIGURATION_ERROR":
      return {
        ...state,
        GetColumnConfigurationResponse: action.updatePayload,
      };
    case "UPDATE_COLUMN_CONFIGURATION_SUCCESS":
    case "UPDATE_COLUMN_CONFIGURATION_ERROR":
      return {
        ...state,
        UpdateColumnConfigurationResponse: action.updatePayload,
      };
    case "GET_ANALYTICS_BRAND_LIST_SUCCESS":
    case "GET_ANALYTICS_BRAND_LIST_ERROR":
      return {
        ...state,
        GetAnalyticsBrandListResponse: action.updatePayload,
      };
    case "GET_ANALYTICS_BRAND_CHART_SUCCESS":
    case "GET_ANALYTICS_BRAND_CHART_ERROR":
      return {
        ...state,
        GetAnalyticsBrandChartResponse: action.updatePayload,
      };

    case "GET_ANALYTICS_SKU_LIST_SUCCESS":
    case "GET_ANALYTICS_SKU_LIST_ERROR":
      return {
        ...state,
        GetAnalyticsSKUListResponse: action.updatePayload,
      };
    case "GET_ANALYTICS_SKU_CHART_SUCCESS":
    case "GET_ANALYTICS_SKU_CHART_ERROR":
      return {
        ...state,
        GetAnalyticsSKUChartResponse: action.updatePayload,
      };

    case "GET_ANALYTICS_CATEGORY_LIST_SUCCESS":
    case "GET_ANALYTICS_CATEGORY_LIST_ERROR":
      return {
        ...state,
        GetAnalyticsCategoryListResponse: action.updatePayload,
      };
    case "GET_ANALYTICS_CATEGORY_CHART_SUCCESS":
    case "GET_ANALYTICS_CATEGORY_CHART_ERROR":
      return {
        ...state,
        GetAnalyticsCategoryChartResponse: action.updatePayload,
      };

    case "GET_ANALYTICS_ASIN_LIST_SUCCESS":
    case "GET_ANALYTICS_ASIN_LIST_ERROR":
      return {
        ...state,
        GetAnalyticsASINListResponse: action.updatePayload,
      };
    case "GET_ANALYTICS_ASIN_CHART_SUCCESS":
    case "GET_ANALYTICS_ASIN_CHART_ERROR":
      return {
        ...state,
        GetAnalyticsCategoryChartResponse: action.updatePayload,
      };

    case "GET_MARKETPLACE_ANALYTICS_LIST_SUCCESS":
    case "GET_MARKETPLACE_ANALYTICS_LIST_ERROR":
      return {
        ...state,
        GetMarketplaceListResponse: {
          ...action.updatePayload,
          data:
            action.updatePayload?.data?.marketplace_list ||
            action.updatePayload?.data,
          currency_sign_list:
            action.updatePayload?.data?.currency_sign_list || {},
        },
      };

    case "GET_ORDER_HISTORY_LIST_SUCCESS":
    case "GET_ORDER_HISTORY_LIST_ERROR":
      return {
        ...state,
        GetOrderHistoryListResponse: action.updatePayload,
      };

    case "FAKE_ACTION_ANALYTICS_BRAND":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
