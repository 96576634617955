import { message } from "antd";
import Nprogress from "nprogress";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PageLoader from "../../components/page-loader";
import { urlDecode } from "../../config";
import "./progress.css";

export default function asyncComponent(DisplayComponent) {
  class AsyncFunc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        mounted: true,
      };
      Nprogress.configure({ showSpinner: false });
      Nprogress.start();
      document.title += ".";
    }

    async componentDidMount() {
      setTimeout(() => {
        message.destroy();
        Nprogress.done();
        this.setState({
          mounted: false,
        });
      }, 400);
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    render() {
      const { mounted } = this.state;
      const { location } = this.props;
      if (mounted === true) {
        return <PageLoader />;
      }
      if (
        Object.keys(urlDecode(location))?.filter((d) => !d)?.length === 0 &&
        location?.search &&
        location?.pathname !== "/spapi-callback"
      ) {
        return (
          <Redirect
            to={{
              pathname: location?.pathname,
              search: "",
              state: urlDecode(location),
            }}
          />
        );
      }
      return (
        <div className="globalHeight">
          <DisplayComponent {...this.props} />
        </div>
      );
    }
  }
  return AsyncFunc;
}
