export default (state, action) => {
    if (typeof state === "undefined") {
      return [];
    }
    switch (action.type) {
      case "GET_COMPANY_EMPLOYEES_LIST_SUCCESS":
      case "GET_COMPANY_EMPLOYEES_LIST_ERROR":
        return {
          ...state,
          GetCompanyEmployeesListResponse: action.updatePayload,
        };
      case "ADD_COMPANY_EMPLOYEES_SUCCESS":
      case "ADD_COMPANY_EMPLOYEES_ERROR":
        return {
          ...state,
          AddCompanyEmployeesResponse: action.updatePayload,
        };
      case "UPDATE_COMPANY_EMPLOYEES_SUCCESS":
      case "UPDATE_COMPANY_EMPLOYEES_ERROR":
        return {
          ...state,
          UpdateCompanyEmployeesResponse: action.updatePayload,
        };
      case "DELETE_COMPANY_EMPLOYEES_SUCCESS":
      case "DELETE_COMPANY_EMPLOYEES_ERROR":
        return {
          ...state,
          DeleteCompanyEmployeesResponse: action.updatePayload,
        };
      
      case "FAKE_ACTION_COMPANY_EMPLOYEES":
        return { ...state, [action?.state]: {} };
      default:
        return state;
    }
  };
  