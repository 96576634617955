import axiosCall from "../../configurations/network-services/axiosCall";

export const loginAction = (payload) => {
  const path = `login`;
  const responseType = "LOGIN";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};
export const signUpAction = (payload) => {
  const path = `signup`;
  const responseType = "SIGNUP";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};
export const forgotPasswordAction = (payload) => {
  const path = `forgot-password`;
  const responseType = "FORGOT_PASSWORD";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};
export const resetPasswordAction = (payload) => {
  const path = `reset-password`;
  const responseType = "RESET_PASSWORD";
  const body = payload;
  return axiosCall("put", path, responseType, body);
};

export const checkEmailAction = (payload) => {
  const path = `user/check-email`;
  const responseType = "CREATE_USER_CHECK_EMAIL";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};

export const userActiveAction = (payload) => {
  const path = `user-activate`;
  const responseType = "USER_ACTIVE";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};

export const fakeActionAuth = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_AUTH", state: data });
};
