export const filterList = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];
export const filterListDirectory = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
export const offerGrid = [
  "Add-on item",
  "Comment",
  "Condition",
  "FBA",
  "First seen",
  "Hidden",
  "Last seen",
  "Potential Scam",
  "Pre-order",
  "Price History",
  "Prime",
  "Prime exclusive",
  "Seller: Name",
  "Seller: Rating",
  "Seller: Review Count",
  "Shippable",
  "Sold by Amazon",
  "Stock: Sold",
  "Stock: Sold 30 days",
  "Stock: Stock",
  "Stock: Stock History",
  "Total (Price + Shipping Costs)",
  "Warehouse Deal",
  "Shipping",
  "Prime",
  "Free shipping",
  "FBM only",
  "Condition",
  "New",
  "Used",
  "Like New",
  "Additional options",
  "Include historical offers",
  "Show potential scam offers",
];
export const productResearchGrid = {
  asin: "Asin",
  title: "Title",
  parentAsin: "ParentAsin",
  imageCSV: "Image",
  buyBoxSellerIdHistory: "BuyBoxSellerIdHistory",
  salesRankReference: "SalesRankReference",
  ratingReviews: "ReviewsRating",
  reviewsCount: "ReviewsCount",
  productPartNumber: "ProductPartNumber",
  manufacturer: "Manufacturer",
  brand: "Brand",
  productGroup: "ProductGroup",
  model: "Model",
  color: "Color",
  size: "Size",
  binding: "Binding",
  lastPriceChange: "LastPriceChange",
  trackingSince: "TrackingSince",
  rootCategory: "RootCategory",
  categoryName: "CategoryName",
  categoryId: "CategoryId",
  reviewCount90DaysAvg: "ReviewCount90DaysAvg",
  reviewCount180DaysAvg: "ReviewCount180DaysAvg",
  packageLength: "PackageLength",
  packageHeight: "PackageHeight",
  packageWidth: "PackageWidth",
  packageWeight: "PackageWeight",
  packageQuantity: "PackageQuantity",
  itemWeight: "ItemWeight",
  releaseDate: "ReleaseDate",
  publicationDate: "PublicationDate",
  numberOfPages: "NumberOfPages",
  numberOfItems: "NumberOfItems",
  author: "Author",
  format: "Format",
  edition: "Edition",
  isAdultProduct: "IsAdultProduct",
  isEligibleForTradeIn: "IsEligibleForTradeIn",
  type: "Type",
  amazonCurrent: "CurrentAmazon",
  amazon30DaysAvg: "Amazon30DaysAvg",
  amazon90DaysAvg: "Amazon90DaysAvg",
  amazon180DaysAvg: "Amazon180DaysAvg",
  usedCurrent: "UsedCurrent",
  used30DaysAvg: "Used30DaysAvg",
  used90DaysAvg: "Used90DaysAvg",
  used180DaysAvg: "Used180DaysAvg",
  usedOOS90Days: "UsedOOS90Days",
  lightningDealsCurrent: "CurrentlightningDeals",
  warehouseDealsCurrent: "CurrentWarehouseDeals",
  warehouseDeals30DaysAvg: "WarehouseDeals30DaysAvg",
  warehouseDeals90DaysAvg: "WarehouseDeals90DaysAvg",
  warehouseDeals180DaysAvg: "WarehouseDeals180DaysAvg",
  currentCollectible: "CurrentCollectible",
  collectible30daysAvg: "Collectible30daysAvg",
  collectible90daysAvg: "Collectible90daysAvg",
  collectible180daysAvg: "Collectible180daysAvg",
  new3rdPartyFBMcurrent: "New3rdPartyFBMcurrent",
  new3rdPartyFBM30Days: "New3rdPartyFBM30Days",
  new3rdPartyFBM90Days: "New3rdPartyFBM90Days",
  new3rdPartyFBM180Days: "New3rdPartyFBM180Days",
  new90DaysOOS: "New90DaysOOS",
  buyBoxCurrent: "BuyBoxCurrent",
  buyBox90DaysAvg: "BuyBox90DaysAvg",
  buyBoxOOS90Days: "BuyBoxOOS90Days",
  buyBox180DaysAvg: "BuyBox180DaysAvg",
  newOfferCount: "NewOfferCount",
  newOfferCount30DaysAvg: "NewOfferCount30DaysAvg",
  newOfferCount90DaysAvg: "NewOfferCount90DaysAvg",
  newOfferCount180DaysAvg: "NewOfferCount180DaysAvg",
  usedOfferCountCurrent: "UsedOfferCountCurrent",
  usedOfferCount30DaysAvg: "UsedOfferCount30DaysAvg",
  usedOfferCount90DaysAvg: "UsedOfferCount90DaysAvg",
  usedOfferCount180DaysAvg: "UsedOfferCount180DaysAvg",
  offerCountFBA: "FBA Offers",
  offerCountFBM: "FBM Offers",
  buyBoxStock: "BuyBoxStock",
  tradeInCurrent: "TradeInCurrent",
  tradeIn30DaysAvg: "TradeIn30DaysAvg",
  tradeIn90DaysAvg: "TradeIn90DaysAvg",
  newEbayCurrent: "NewEbayCurrent",
  newEbay30DaysAvg: "NewEbay30DaysAvg",
  newEbay90DaysAvg: "NewEbay90DaysAvg",
  newEbay180DaysAvg: "NewEbay180DaysAvg",
  usedEbayCurrent: "UsedEbayCurrent",
  usedEbay30DaysAvg: "UsedEbay30DaysAvg",
  usedEbay90DaysAvg: "UsedEbay90DaysAvg",
  usedEbay180DaysAvg: "UsedEbay180DaysAvg",
  refurbishedCurrent: "RefurbishedCurrent",
  refurbished30DaysAvg: "Refurbished30DaysAvg",
  refurbished90DaysAvg: "Refurbished90DaysAvg",
  refurbished180DaysAvg: "Refurbished180DaysAvg",
  likeNewCollectibleCurrent: "LikeNewCollectibleCurrent",
  likeNewCollectible30DaysAvg: "LikeNewCollectible30DaysAvg",
  likeNewCollectible90DaysAvg: "LikeNewCollectible90DaysAvg",
  likeNewCollectible180DaysAvg: "LikeNewCollectible180DaysAvg",
  veryGoodCollectibleCurrent: "VeryGoodCollectibleCurrent",
  veryGoodCollectible30DaysAvg: "VeryGoodCollectible30DaysAvg",
  veryGoodCollectible90DaysAvg: "VeryGoodCollectible90DaysAvg",
  veryGoodCollectible180DaysAvg: "VeryGoodCollectible180DaysAvg",
  goodCollectibleCurrent: "GoodCollectibleCurrent",
  goodCollectible90DaysAvg: "GoodCollectible90DaysAvg",
  acceptableCollectibleCurrent: "AcceptableCollectibleCurrent",
  acceptableCollectible90DaysAvg: "AcceptableCollectible90DaysAvg",
  newCurrent: "NewCurrent",
  new30DaysAvg: "New30DaysAvg",
  new90DaysAvg: "New90DaysAvg",
  new180DaysAvg: "New180DaysAvg",
  currentSalesRank: "Sales Rank",
  salesRank30daysAvg: "SalesRank30daysAvg",
  salesRank90daysAvg: "SalesRank90daysAvg",
  salesRank180daysAvg: "SalesRank180daysAvg",
  salesRankDrop30days: "SalesRankDrop30days",
  salesRankDrop90days: "SalesRankDrop90days",
  salesRankDrop180days: "SalesRankDrop180days",
  lowestSellerFBM: "LowestSellerFBM",
  amazon90DaysOOS: "AOS%",
  buyBoxSellerId: "BuyBoxSellerId",
  usedLikeNewCurrent: "UsedLikeNewCurrent",
  usedLikeNew30daysAvg: "UsedLikeNew30daysAvg",
  usedLikeNew90daysAvg: "UsedLikeNew90daysAvg",
  usedLikeNew180daysAvg: "UsedLikeNew180daysAvg",
  usedVeryGoodCurrent: "UsedVeryGoodCurrent",
  usedVeryGood90daysAvg: "UsedVeryGood90daysAvg",
  usedGoodCurrent: "UsedGoodCurrent",
  usedGood30daysAvg: "UsedGood30daysAvg",
  usedGood90daysAvg: "UsedGood90daysAvg",
  usedGood180daysAvg: "UsedGood180daysAvg",
  refurbishedOfferCountCurrent: "RefurbishedOfferCountCurrent",
  refurbishedOfferCount30daysAvg: "RefurbishedOfferCount30daysAvg",
  refurbishedOfferCount90daysAvg: "RefurbishedOfferCount90daysAvg",
  refurbishedOfferCount180daysAvg: "RefurbishedOfferCount180daysAvg",
  collectibleOfferCountCurrent: "CollectibleOfferCountCurrent",
  collectibleOfferCount30daysAvg: "CollectibleOfferCount30daysAvg",
  collectibleOfferCount90daysAvg: "CollectibleOfferCount90daysAvg",
  collectibleOfferCount180daysAvg: "CollectibleOfferCount180daysAvg",
  listPriceCurrent: "ListPriceCurrent",
  listPrice30DaysAvg: "ListPrice30DaysAvg",
  listPrice90DaysAvg: "ListPrice90DaysAvg",
  listPrice180DaysAvg: "ListPrice180DaysAvg",
  productCodeEAN: "EAN",
  productCodeUPC: "UPC",
  estimatedSales: "Est. Sales",
  estimatedRevenue: "Est. Revenue",
  variationASINs: "Variation ASINs",
  avgNewOfferCount: "AvgNewOfferCount",
  reviewsCount90DaysAvg: "ReviewCount90DaysAvg",
};
export const CompetitorResearchFilterList = [
  {
    name: "Price Range",
    sign: "",
    MinValue: "MinPriceRange",
    MaxValue: "MaxPriceRange",
  },
  {
    name: "Rank Range",
    sign: "",
    MinValue: "MinRankRange",
    MaxValue: "MaxRankRange",
  },
  {
    name: "Revenue Range",
    sign: "",
    MinValue: "MinRevenueRange",
    MaxValue: "MaxRevenueRange",
  },
  {
    name: "FBA Sellers",
    sign: "",
    MinValue: "MinOfferCountFBA",
    MaxValue: "MaxOfferCountFBA",
  },
  {
    name: "FBM Sellers",
    sign: "",
    MinValue: "MinOfferCountFBM",
    MaxValue: "MaxOfferCountFBM",
  },
];
export const ProductResearchFilterList = [
  {
    name: "Amazon Out Of Stock",
    sign: "%",
    MinValue: "MinStockRate",
    MaxValue: "MaxStockRate",
  },
  // {
  //   name: "Average Number of Sellers",
  //   sign: "",
  //   MinValue: "MinAvgNumberSeller",
  //   MaxValue: "MaxAvgNumberSeller",
  // },
  // {
  //   name: "Monthly Revenue",
  //   sign: "$",
  //   MinValue: "MinMonthlyRevenue",
  //   MaxValue: "MaxMonthlyRevenue",
  // },

  // {
  //   name: "Average Review Rating",
  //   sign: "",
  //   MinValue: "MinAvgReviewRating",
  //   MaxValue: "MaxAvgReviewRating",
  // },
  {
    name: "Reviews - Rating",
    sign: "",
    MinValue: "MinAvgReviewRating",
    MaxValue: "MaxAvgReviewRating",
  },
  // {
  //   name: "Total Number of Reviews",
  //   sign: "",
  //   MinValue: "MinTotalNumberReviews",
  //   MaxValue: "MaxTotalNumberReviews",
  // },
  {
    name: "Price Range",
    sign: "$",
    MinValue: "MinPriceRange",
    MaxValue: "MaxPriceRange",
  },
  {
    name: "Sales Rank Range",
    sign: "",
    MinValue: "MinRankRange",
    MaxValue: "MaxRankRange",
  },
  // {
  //   name: "Average Number of FBA Sellers",
  //   sign: "",
  //   MinValue: "MinAvgNumberFbaSeller",
  //   MaxValue: "MaxAvgNumberFbaSeller",
  // },
  // {
  //   name: "Average Selling Price",
  //   sign: "$",
  //   MinValue: "MinAvgSellingPrice",
  //   MaxValue: "MaxAvgSellingPrice",
  // },

  {
    name: "Revenue Range",
    sign: "$",
    MinValue: "MinRevenueRange",
    MaxValue: "MaxRevenueRange",
  },
  {
    name: "Average No. of FBA sellers",
    sign: "",
    MinValue: "MinAvgNumberFbaSeller",
    MaxValue: "MaxAvgNumberFbaSeller",
  },
  {
    name: "Average No. of FBM sellers",
    sign: "",
    MinValue: "MinAvgNumberFbmSeller",
    MaxValue: "MaxAvgNumberFbmSeller",
  },
  // {
  //   name: "Total Sellers ",
  //   sign: "",
  //   MinValue: "MinTotalSeller",
  //   MaxValue: "MaxTotalSeller",
  // },
];
export const ProductResearchFilters = [
  // {
  //   name: "Average Size of Product",
  //   params: "AvgSizeProduct",
  // },
];
// export const ProductResearchFilters = [
//   {
//     name: "Min Price Range",
//     params: "MinPriceRange",
//   },
//   {
//     name: "Max Price Range",
//     params: "MaxPriceRange",
//   },
//   {
//     name: "Min Rank Range",
//     params: "MinRankRange",
//   },
//   {
//     name: "Max Rank Range",
//     params: "MaxRankRange",
//   },
//   {
//     name: "Min Revenue Range",
//     params: "MinRevenueRange",
//   },
//   {
//     name: "Max Revenue Range",
//     params: "MaxRevenueRange",
//   },
//   {
//     name: "Min Total Sellers",
//     params: "MinTotalSeller",
//   },
//   {
//     name: "Max Total Sellers",
//     params: "MaxTotalSeller",
//   },
//   {
//     name: "Average Size of Product",
//     params: "AvgSizeProduct",
//   },
// ];
// export const ProductResearchFilterList = [
//   {
//     name: "Amazon In Stock Rate",
//     sign: "%",
//     MinValue: "MinStockRate",
//     MaxValue: "MaxStockRate",
//   },
//   {
//     name: "Average Number of Sellers",
//     sign: "",
//     MinValue: "MinAvgNumberSeller",
//     MaxValue: "MaxAvgNumberSeller",
//   },
//   {
//     name: "Average Selling Price",
//     sign: "$",
//     MinValue: "MinAvgSellingPrice",
//     MaxValue: "MaxAvgSellingPrice",
//   },
//   {
//     name: "Monthly Revenue",
//     sign: "$",
//     MinValue: "MinMonthlyRevenue",
//     MaxValue: "MaxMonthlyRevenue",
//   },

//   {
//     name: "Average Review Rating",
//     sign: "",
//     MinValue: "MinAvgReviewRating",
//     MaxValue: "MaxAvgReviewRating",
//   },
//   {
//     name: "Total Number of Reviews",
//     sign: "",
//     MinValue: "MinTotalNumberReviews",
//     MaxValue: "MaxTotalNumberReviews",
//   },
//   {
//     name: "Average Number of FBA Sellers",
//     sign: "",
//     MinValue: "MinAvgNumberFbaSeller",
//     MaxValue: "MaxAvgNumberFbaSeller",
//   },
// ];
export const competitorResearchFilterList = [
  {
    name: "Price Range",
    sign: "",
    MinValue: "MinPriceRange",
    MaxValue: "MaxPriceRange",
  },

  {
    name: "Rank Range",
    sign: "",
    MinValue: "MinRankRange",
    MaxValue: "MaxRankRange",
  },

  {
    name: "Revenue Range",
    sign: "",
    MinValue: "MinRevenueRange",
    MaxValue: "MaxRevenueRange",
  },
  {
    name: "Total FBA Sellers",
    sign: "",
    MinValue: "MinOfferCountFBA",
    MaxValue: "MaxOfferCountFBA",
  },
  {
    name: "Total FBM Sellers",
    sign: "",
    MinValue: "MinOfferCountFBM",
    MaxValue: "MaxOfferCountFBM",
  },
];

const spapimenus = [
  {
    title: "Order Data",
    path: "/sp-api-reports/orders-data",
  },
  {
    title: "Return Order Data",
    path: "/sp-api-reports/return-order-data",
  },
  {
    title: "Archived Inventory",
    path: "/sp-api-reports/archived-inventory",
  },
  {
    title: "Reserved Inventory",
    path: "/sp-api-reports/reserved-inventory",
  },
  {
    title: "Planning Inventory",
    path: "/sp-api-reports/planning-inventory",
  },
  {
    title: "Stranded Inventory",
    path: "/sp-api-reports/stranded-inventory",
  },
  {
    title: "Finance Data",
    path: "/sp-api-reports/finance-data",
  },
  {
    title: "Product Data",
    path: "/sp-api-reports/product-data",
  },
  {
    title: "Reimbursements",
    path: "/sp-api-reports/reimbursements",
  },
];
const applicationLogs = [
  {
    title: "SQS Messages",
    path: "/application-logs/sqs-messages",
  },
  {
    title: "Repricing Notification",
    path: "/application-logs/repricing-notification",
  },
  {
    title: "Central log system",
    path: "/application-logs/central-log-system",
  },
  {
    title: "System event log",
    path: "/application-logs/system-event-log",
  },
];
const masterTableChild = [
  {
    title: "Brands",
    path: "/master-table/brands",
  },
  {
    title: "SKU Data",
    path: "/master-table/sku-data",
  },
  {
    title: "Expenses",
    path: "/master-table/expenses",
  },
  {
    title: "Supplier Setup",
    path: "/master-table/supplier-setup",
  },
];
export const nameObject = {
  "/": {
    name: "Dashboard",
    breadIcon: "dashboard",
  },
  "/item-master": {
    name: "Item-Master",
  },
  "/dashboard": {
    name: "Dashboard",
    breadIcon: "dashboard",
  },
  "/brand-rules": {
    name: "Brand Rules",
    breadIcon: "pricing",
  },
  "/inventory/replenishment": {
    name: "Replenishment",
    parent: "Inventory",
    parentChild: [
      {
        title: "Replenishment",
        path: "/inventory/replenishment",
      },
      {
        title: "Reconciliation",
        path: "/inventory/reconciliation",
      },
      {
        title: "POList",
        path: "/inventory/polist",
      },
    ],
    breadIcon: "wholesale-supplier",
  },
  "/inventory/replenishment/asin-details": {
    name: "ASIN Details",
    parent: "Replenishment",
    breadIcon: "details",
    path: "/inventory/replenishment",
  },
  "/inventory/reconciliation": {
    name: "Reconciliation",
    parent: "Inventory",
    parentChild: [
      {
        title: "Replenishment",
        path: "/inventory/replenishment",
      },
      {
        title: "Reconciliation",
        path: "/inventory/reconciliation",
      },
      {
        title: "POList",
        path: "/inventory/polist",
      },
    ],
    breadIcon: "wholesale-supplier",
  },
  "/inventory/polist": {
    name: "POList",
    parent: "Inventory",
    parentChild: [
      {
        title: "Replenishment",
        path: "/inventory/replenishment",
      },
      {
        title: "Reconciliation",
        path: "/inventory/reconciliation",
      },
      {
        title: "POList",
        path: "/inventory/polist",
      },
    ],
    breadIcon: "wholesale-supplier",
  },
  "/listing-optimizer": {
    name: "Listing Optimizer",
    breadIcon: "keyword-tracker",
  },
  "/listing-optimizer/keyword-tracker": {
    name: "Keyword Tracker",
    parent: "Listing Optimizer",
    breadIcon: "keyword-tracker",
    path: "/listing-optimizer",
  },
  "/manage-seller-users": {
    name: "Manage Seller Users",
    breadIcon: "users",
  },
  "/user-feedback": {
    name: "User Feedback",
    breadIcon: "users",
  },
  "/categories": {
    name: "Categories",
    breadIcon: "categories",
  },
  "/estimated-sales": {
    name: "Estimated Sales",
    breadIcon: "estimated-sales",
  },
  "/brand-wise-analytics": {
    name: "Brand Wise Analytics",
    breadIcon: "details",
    parent: "Product Research",
    path: "/product-research",
  },
  "/data-scheduler": {
    name: "Data Scheduler",
    breadIcon: "data-scheduler",
  },
  "/master-table/brands": {
    name: "Brands",
    parent: "Master Table",
    breadIcon: "menuSideBar",
    parentChild: masterTableChild,
  },
  "/master-table/sku-data": {
    name: "SKU Data",
    parent: "Master Table",
    breadIcon: "menuSideBar",
    parentChild: masterTableChild,
  },
  "/master-table/expenses": {
    name: "Expenses",
    parent: "Master Table",
    breadIcon: "menuSideBar",
    parentChild: masterTableChild,
  },
  "/master-table/supplier-setup": {
    name: "Supplier Setup",
    parent: "Master Table",
    breadIcon: "menuSideBar",
    parentChild: masterTableChild,
  },
  "/application-logs/central-log-system": {
    name: "Central log system",
    parent: "Application logs",
    breadIcon: "admin-menu",
    parentChild: applicationLogs,
  },
  "/application-logs/sqs-messages": {
    name: "SQS Messages",
    parent: "Application logs",
    breadIcon: "admin-menu",
    parentChild: applicationLogs,
  },
  "/sp-api-reports": {
    name: "SP API Reports",
    breadIcon: "competitor-research",
  },
  // "/sp-api-reports/orders-data": {
  //   name: "Order Data",
  //   parent: "SP API Reports",
  //   parentChild: spapimenus,
  //   breadIcon: "competitor-research",
  // },
  // "/sp-api-reports/stranded-inventory": {
  //   name: "Stranded Inventory",
  //   parent: "SP API Reports",
  //   parentChild: spapimenus,
  //   breadIcon: "competitor-research",
  // },
  // "/sp-api-reports/planning-inventory": {
  //   name: "Planning Inventory",
  //   parent: "SP API Reports",
  //   parentChild: spapimenus,
  //   breadIcon: "competitor-research",
  // },
  // "/sp-api-reports/return-order-data": {
  //   name: "Return Order Data",
  //   parent: "SP API Reports",
  //   parentChild: spapimenus,
  //   breadIcon: "competitor-research",
  // },
  // "/sp-api-reports/archived-inventory": {
  //   name: "Archived Inventory",
  //   parent: "SP API Reports",
  //   parentChild: spapimenus,
  //   breadIcon: "competitor-research",
  // },
  // "/sp-api-reports/reserved-inventory": {
  //   name: "Reserved Inventory",
  //   parent: "SP API Reports",
  //   parentChild: spapimenus,
  //   breadIcon: "competitor-research",
  // },
  // "/sp-api-reports/finance-data": {
  //   name: "Finance Data",
  //   parent: "SP API Reports",
  //   parentChild: spapimenus,
  //   breadIcon: "competitor-research",
  // },
  // "/sp-api-reports/product-data": {
  //   name: "Product Data",
  //   parent: "SP API Reports",
  //   parentChild: spapimenus,
  //   breadIcon: "competitor-research",
  // },
  // "/sp-api-reports/reimbursements": {
  //   name: "Reimbursements",
  //   parent: "SP API Reports",
  //   parentChild: spapimenus,
  //   breadIcon: "competitor-research",
  // },
  "/repricing/products": {
    parent: "Repricing",
    name: "Products",
    breadIcon: "layers",
    parentChild: [
      {
        title: "Products",
        path: "/repricing/products",
      },
      {
        title: "Amazon Repricing Rules",
        path: "/repricing/amazon-repricing-rules",
      },
      {
        title: "Advanced Repricing Rules",
        path: "/repricing/advanced-repricing-rules",
      },
      {
        title: "Repricing Product",
        path: "/repricing/repricing-product",
      },
    ],
  },
  "/repricing/amazon-repricing-rules": {
    parent: "Repricing",
    name: "Amazon Repricing Rules",
    breadIcon: "layers",
    parentChild: [
      {
        title: "Products",
        path: "/repricing/products",
      },
      {
        title: "Amazon Repricing Rules",
        path: "/repricing/amazon-repricing-rules",
      },
      {
        title: "Advanced Repricing Rules",
        path: "/repricing/advanced-repricing-rules",
      },
      {
        title: "Repricing Product",
        path: "/repricing/repricing-product",
      },
    ],
  },
  "/repricing/advanced-repricing-rules": {
    parent: "Repricing",
    name: "Advanced Repricing Rules",
    breadIcon: "layers",
    parentChild: [
      {
        title: "Products",
        path: "/repricing/products",
      },
      {
        title: "Amazon Repricing Rules",
        path: "/repricing/amazon-repricing-rules",
      },
      {
        title: "Advanced Repricing Rules",
        path: "/repricing/advanced-repricing-rules",
      },
      {
        title: "Repricing Product",
        path: "/repricing/repricing-product",
      },
    ],
  },
  "/repricing/repricing-product": {
    parent: "Repricing",
    name: "Repricing Product",
    breadIcon: "layers",
    parentChild: [
      {
        title: "Products",
        path: "/repricing/products",
      },
      {
        title: "Amazon Repricing Rules",
        path: "/repricing/amazon-repricing-rules",
      },
      {
        title: "Advanced Repricing Rules",
        path: "/repricing/advanced-repricing-rules",
      },
      {
        title: "Repricing Product",
        path: "/repricing/repricing-product",
      },
    ],
  },
  "/repricing/amazon-repricing-rules/add-rules": {
    parent: "Amazon Repricing Rules",
    name: "Add Rules",
    breadIcon: "layers",
  },
  // "/repricing/competitors": {
  //   parent: "Repricing",
  //   name: "Competitors",
  //   breadIcon: "layers",
  // },
  "/algorithmic-repricer/product-repricing": {
    name: "Product Repricing",
    parent: "Algorithmic Repricer",
    parentChild: [
      {
        title: "Product Repricing",
        path: "/algorithmic-repricer/product-repricing",
      },
      {
        title: "Repricing Rule",
        path: "/algorithmic-repricer/repricing-rules",
      },
    ],
    breadIcon: "wholesale-supplier",
  },
  "/algorithmic-repricer/repricing-rules": {
    name: "Repricing Rules",
    parent: "Algorithmic Repricer",
    parentChild: [
      {
        title: "Product Repricing",
        path: "/algorithmic-repricer/product-repricing",
      },
      {
        title: "Repricing Rule",
        path: "/algorithmic-repricer/repricing-rules",
      },
    ],
    breadIcon: "wholesale-supplier",
  },

  "/application-logs/repricing-notification": {
    name: "Repricing Notification",
    parent: "Application logs",
    breadIcon: "admin-menu",
    parentChild: applicationLogs,
  },
  "/application-logs/system-event-log": {
    name: "System event log",
    parent: "Application logs",
    breadIcon: "admin-menu",
    parentChild: applicationLogs,
  },
  "/inventory-management": {
    name: "Inventory Management",
  },
  "/inventory-forecasting": {
    name: "Inventory Forecasting",
  },
  "/product-research": {
    name: "Product Research",
    breadIcon: "product-research",
  },
  "/product-research/details": {
    name: "Details",
    parent: "Product Research",
    breadIcon: "details",
    path: "/product-research",
  },
  "/competitor-research": {
    name: "Competitor Research",
    breadIcon: "competitor-research",
  },
  "/wholesale-supplier-directory": {
    name: "Wholesale Suppliers Directory",
    breadIcon: "wholesale-supplier",
  },
  "/wholesale-supplier-list": {
    name: "List",
    parent: "Wholesale Suppliers Directory",
    path: "/wholesale-supplier-directory",
  },
  "/profitability-calculator": {
    name: "Profitability Calculator",
    breadIcon: "profitability-calculator",
  },
  "/feedback-automation": {
    name: "Feedback Automation",
    breadIcon: "profitability-calculator",
  },
  "/bulk-profitability-calculator": {
    name: "Bulk Profitability Calculator",
    breadIcon: "profitability-calculator",
  },
  "/bulk-profitability-calculator/details": {
    name: "Details",
    parent: "Bulk Profitability Calculator",
    path: "/bulk-profitability-calculator",
    breadIcon: "details",
  },
  "/sales-analytics": {
    name: "Sales Analytics",
    breadIcon: "product-research",
  },
  // "/sales-analytics/category": {
  //   name: "Analytics by Category",
  //   parent: "Sales Analytics",
  // },
  "/competitor-search-analytics/analytics-by-brand": {
    name: "Analytics By Brand",
    parent: "Competitor Search Analytics",
    breadIcon: "details",
  },
  // "/sales-analytics/sku": {
  //   name: "Analytics by SKU",
  //   parent: "Sales Analytics",
  // },
  // "/sales-analytics/asin": {
  //   name: "Analytics by ASIN",
  //   parent: "Sales Analytics",
  // },
  // "/sales-analytics/brand": {
  //   name: "Analytics by Brand",
  //   parent: "Sales Analytics",
  // },
  "/manage-company-employee": {
    name: "Manage Company Employee",
    breadIcon: "manage-seller-user",
  },
  "/manage-subscription": {
    name: "Manage Subscription",
    breadIcon: "pricing",
  },
  "/pricing-plans": {
    name: "Pricing Plans",
    breadIcon: "pricing",
  },
  "/payment-history": {
    name: "Payment History",
    breadIcon: "payment-history",
  },
  // "/profile": {
  //   name: "Profile",
  //   breadIcon: "profile",
  // },
  "/my-list": {
    name: "My List",
  },
  // "/central-log-system": {
  //   name: "Central Log",
  // },
  "/setting": {
    name: "Setting",
    breadIcon: "setting",
  },
  "/setting/app-configuration": {
    name: "App Configuration",
    parent: "Setting",
  },
  "/setting/admin-scheduler": {
    name: "Admin Scheduler",
    parent: "Setting",
  },
  "/spapi-callback": {
    name: "Amazon SP API Credentials",
    parent: "Setting",
    path: "/setting",
  },
  "/callbackads": {
    name: "Advertising Credentials",
    parent: "Setting",
    path: "/setting",
  },
  "/reimbursement": {
    name: "Reimbursement",
    breadIcon: "competitor-research",
  },
};
