export default (state, action) => {
    if (typeof state === "undefined") {
      return [];
    }
    switch (action.type) {
      case "GET_REPORT_STRANDED_INVENTORY_LIST_SUCCESS":
      case "GET_REPORT_STRANDED_INVENTORY_LIST_ERROR":
        return {
          ...state,
          GetReportStrandedInventoryListResponse: action.updatePayload,
        };
      case "FAKE_ACTION_MANAGE_REPORTS_STRANDED_INVENTORY":
        return { ...state, [action?.state]: {} };
      default:
        return state;
    }
  };
  