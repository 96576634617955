import { Button, Result } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import ErrorWrapper from "./style";

export default function (props) {
  const { status } = props;
  const history = useHistory();
  const getMenu = () => {
    return localStorage.getItem("menusList")
      ? JSON.parse(localStorage.getItem("menusList")) || []
      : [];
  };
  return (
    <ErrorWrapper>
      {status === 1 ? (
        <>
          {/* <img
            style={{
              height: "65%",
              mixBlendMode: "darken",
              inset: "0 0 0 0",
              margin: "auto",
            }}
            onError={(e) => {
              e.target.src =
                "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png";
            }}
            src="/media/404 error with a tired person-amico.svg"
            loading="lazy"
          /> */}

          <div className="d-flex justify-content-center align-items-center">
            <div style={{ padding: "15px" }}>
              <svg
                width="350px"
                height="500px"
                viewBox="0 0 837 1045"
                version="1.1"
                // xmlns="http://www.w3.org/2000/svg"
                // xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path
                    d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
                    id="Polygon-1"
                    stroke="#007FB2"
                    stroke-width="6"
                  ></path>
                  <path
                    d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
                    id="Polygon-2"
                    stroke="#EF4A5B"
                    stroke-width="6"
                  ></path>
                  <path
                    d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
                    id="Polygon-3"
                    stroke="#795D9C"
                    stroke-width="6"
                  ></path>
                  <path
                    d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
                    id="Polygon-4"
                    stroke="#F2773F"
                    stroke-width="6"
                  ></path>
                  <path
                    d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
                    id="Polygon-5"
                    stroke="#36B455"
                    stroke-width="6"
                  ></path>
                </g>
              </svg>
            </div>
            <div style={{ padding: "15px" }}>
              <div class="container404">
                <div class="block">4</div>
                <div class="block">0</div>
                <div class="block">4</div>
              </div>
              <div className="d-inline-flex justify-content-center w-100">
                <h1 style={{ color: "#606060", fontSize: "25px" }}>
                  Page not found
                </h1>
              </div>
            </div>
          </div>

          <button
            onClick={() => {
              if (getMenu()?.length !== 0) {
                return history.replace(`/${getMenu()?.[0]}`);
              }
              history.replace(
                localStorage.getItem("userType") == 2
                  ? `/dashboard`
                  : `/manage-seller-users`
              );
            }}
            style={{ textTransform: "capitalize" }}
            className="btn btn-primary"
          >
            Back to &nbsp;
            {getMenu().length === 0
              ? "Home"
              : getMenu()?.[0]?.split("-").join(" ")}
          </button>
        </>
      ) : status === 2 ? (
        <>
          <img
            style={{
              height: "calc(100vh - 285px)",
              mixBlendMode: "darken",
              inset: "0 0 0 0",
              margin: "auto",
            }}
            onError={(e) => {
              e.target.src =
                "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png";
            }}
            src="/media/unauthorized_401.png"
            loading="lazy"
          />
          <Button
            onClick={() => {
              const menuList = localStorage.getItem("menusList")
                ? JSON.parse(localStorage.getItem("menusList")) || []
                : [];
              if (menuList?.length !== 0) {
                return history.replace(`/${menuList?.[0]}`);
              }
              history.replace(
                localStorage.getItem("userType") == 2
                  ? `/dashboard`
                  : `/manage-seller-users`
              );
            }}
            type="primary"
          >
            Back Home
          </Button>
        </>
      ) : (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button
              onClick={() => {
                const menuList = localStorage.getItem("menusList")
                  ? JSON.parse(localStorage.getItem("menusList")) || []
                  : [];
                if (menuList?.length !== 0) {
                  return history.replace(`/${menuList?.[0]}`);
                }
                history.replace(
                  localStorage.getItem("adminData")
                    ? `/dashboard`
                    : `/manage-seller-users`
                );
              }}
              type="primary"
            >
              Back Home
            </Button>
          }
        />
      )}
    </ErrorWrapper>
  );
}
