export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "ADMIN_SYSTEM_EVENT_PROCESS_SUCCESS":
    case "ADMIN_SYSTEM_EVENT_PROCESS_ERROR":
      return {
        ...state,
        GetSystemEventProcessResponse: action.updatePayload,
      };
    case "GET_SALE_RANK_RANGE_SUCCESS":
    case "GET_SALE_RANK_RANGE_ERROR":
      return {
        ...state,
        GetSaleRankRangeResponse: action.updatePayload,
      };
    case "ADMIN_SYSTEM_EVENT_LOGS_SUCCESS":
    case "ADMIN_SYSTEM_EVENT_LOGS_ERROR":
      return {
        ...state,
        GetSystemEventLogsResponse: action.updatePayload,
      };
    case "USER_SYSTEM_EVENT_PROCESS_SUCCESS":
    case "USER_SYSTEM_EVENT_PROCESS_ERROR":
      return {
        ...state,
        GetUserSystemEventProcessResponse: action.updatePayload,
      };
    case "USER_SYSTEM_EVENT_LOGS_SUCCESS":
    case "USER_SYSTEM_EVENT_LOGS_ERROR":
      return {
        ...state,
        GetUserSystemEventLogsResponse: action.updatePayload,
      };
    case "GET_EVENT_NAME_LIST_USER_SUCCESS":
    case "GET_EVENT_NAME_LIST_USER_ERROR":
      return {
        ...state,
        GetEventNameListUserResponse: action.updatePayload,
      };
    case "GET_ACTIVE_CATEGORY_SUCCESS":
    case "GET_ACTIVE_CATEGORY_ERROR":
      return {
        ...state,
        GetActiveCategoryResponse: action.updatePayload,
      };
    case "SYNC_ASIN_ADMIN_SUCCESS":
    case "SYNC_ASIN_ADMIN_ERROR":
      return {
        ...state,
        SyncAsinAdminResponse: action.updatePayload,
      };
    case "FAKE_ACTION_APP_LOGS":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
