export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_KEY_WORDS_LIST_SUCCESS":
    case "GET_KEY_WORDS_LIST_ERROR":
      return {
        ...state,
        GetKeyWordsListResponse: action.updatePayload,
      };
    case "PRODUCT_LISTING_OPTIMIZER_LIST_SUCCESS":
    case "PRODUCT_LISTING_OPTIMIZER_LIST_ERROR":
      return {
        ...state,
        ProductListingOptimizerListResponse: action.updatePayload,
      };
    case "AI_GENERATE_KEY_WORDS_SUCCESS":
    case "AI_GENERATE_KEY_WORDS_ERROR":
      return {
        ...state,
        AIGenerateKeyWordsResponse: action.updatePayload,
      };
    case "FAKE_ACTION_LISTING_OPTIMIZER_LIST":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
