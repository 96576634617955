export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_MASTER_TABLE_SKU_DATA_LIST_SUCCESS":
    case "GET_MASTER_TABLE_SKU_DATA_LIST_ERROR":
      return {
        ...state,
        GetMasterTableSkuDataListResponse: action.updatePayload,
      };

    case "GET_MASTER_TABLE_SKU_DATA_HISTORY_SUCCESS":
    case "GET_MASTER_TABLE_SKU_DATA_HISTORY_ERROR":
      return {
        ...state,
        GetMasterTableSkuHistoryResponse: action.updatePayload,
      };

    case "GET_MASTER_TABLE_SKU_DATA_UPDATE_SUCCESS":
    case "GET_MASTER_TABLE_SKU_DATA_UPDATE_ERROR":
      return {
        ...state,
        GetMasterTableSkuDataUpdateResponse: action.updatePayload,
      };
    case "MASTER_TABLE_SKU_DATA_UPDATE_SUCCESS":
    case "MASTER_TABLE_SKU_DATA_UPDATE_ERROR":
      return {
        ...state,
        MasterTableSkuDataUpdateResponse: action.updatePayload,
      };
    case "IMPORT_FILE_SKU_SUCCESS":
    case "IMPORT_FILE_SKU_ERROR":
      return {
        ...state,
        ImportFileSKUResponse: action.updatePayload,
      };
    case "GET_MASTER_TABLE_SKU_DATA_ALL_BRANDS_SUCCESS":
    case "GET_MASTER_TABLE_SKU_DATA_ALL_BRANDS_ERROR":
      return {
        ...state,
        GetMasterTableSkuDataAllBrandsResponse: action.updatePayload,
      };
    case "EXPORT_SKU_DATA_SUCCESS":
    case "EXPORT_SKU_DATA_ERROR":
      return {
        ...state,
        ExportSkuDataResponse: action.updatePayload,
      };
    case "GET_MARKETPLACE_SKU_LIST_SUCCESS":
    case "GET_MARKETPLACE_SKU_LIST_ERROR":
      return {
        ...state,
        GetMarketplaceListResponse: {
          ...action.updatePayload,
          data:
            action.updatePayload?.data?.marketplace_list ||
            action.updatePayload?.data,
          currency_sign_list:
            action.updatePayload?.data?.currency_sign_list || {},
        },
      };
    case "FAKE_ACTION_MASTER_TABLES_SKU_DATA":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
