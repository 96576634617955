import styled from "styled-components";

export const ListWrapper = styled.div`


  .ant-input {
    border-radius: 7px;
  }
  .brandFilter > div {
    &::-webkit-scrollbar {
      width: 10px;
      height: 0px;
      transition: 0.8s;
    }
  }
  .title {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    margin: 0px 0px 25px;
    align-items: center;
    .backtoList {
      margin-right: 10px;
      position: relative;
      top: -1px;
    }
  }
  .active {
  }
  .ant-tabs {
    width: 100%;
  }
  .ant-modal-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5;
    word-wrap: break-word;
    text-align: center;
  }
  .ant-modal-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5;
    word-wrap: break-word;
    text-align: center;
  }
  .ant-modal .ant-modal-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5;
    word-wrap: break-word;
    text-align: center !important;
  }
  .ant-select .ant-select-lg {
    font-weight: 400 !important;
  }
  // .ant-table-tbody tr:nth-child(even) {
  //   background: #7e78ef17 !important;
  // }
  // // .ant-table-tbody tr:nth-child(odd) {
  // //   background: #7e78ef17 !important;
  // // }
  // .ant-table-thead > tr {
  //   // background: #7e78ef2e !important;
  //   background: #ef7db170 !important;
  // }
  // .ant-table-thead > tr > th,
  // .ant-table-tbody > tr > td {
  //   background: none;
  // }
`;
