import axiosCall from "../configurations/network-services/axiosCall";

export const CheckSubscription = () => {
  const path = `user/check-subscription`;
  const responseType = "CHECK_SUBSCRIPTION";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionCheckSubscription = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_CHECK_SUBSCRIPTION", state: data });
};
