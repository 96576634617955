export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_MASTER_TABLE_BRAND_LIST_SUCCESS":
    case "GET_MASTER_TABLE_BRAND_LIST_ERROR":
      return {
        ...state,
        GetMasterTableBrandListResponse: action.updatePayload,
      };
    case "GET_MASTER_TABLE_BRAND_ADD_SUCCESS":
    case "GET_MASTER_TABLE_BRAND_ADD_ERROR":
      return {
        ...state,
        GetMasterTableBrandAddResponse: action.updatePayload,
      };
    case "GET_MASTER_TABLE_BRAND_UPDATE_SUCCESS":
    case "GET_MASTER_TABLE_BRAND_UPDATE_ERROR":
      return {
        ...state,
        GetMasterTableBrandUpdateResponse: action.updatePayload,
      };
    case "GET_MASTER_TABLE_BRAND_DELETE_SUCCESS":
    case "GET_MASTER_TABLE_BRAND_DELETE_ERROR":
      return {
        ...state,
        GetMasterTableBrandDeleteResponse: action.updatePayload,
      };
    case "GET_MARKETPLACE_BRAND_LIST_SUCCESS":
    case "GET_MARKETPLACE_BRAND_LIST_ERROR":
      return {
        ...state,
        GetMarketplaceListResponse: {
          ...action.updatePayload,
          data:
            action.updatePayload?.data?.marketplace_list ||
            action.updatePayload?.data,
          currency_sign_list:
            action.updatePayload?.data?.currency_sign_list || {},
        },
      };
    case "FAKE_ACTION_MASTER_TABLES_BRAND":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
