export const bodyBackground = "rgb(224, 228, 233)"; //-------------------Old
// export const bodyBackground = "#3c4146"; //-------------------New

// export const sidebarBackground = "#f9efed"; //-------------------Old
export const sidebarBackground = "#7673f1 "; //-------------------New

// export const sidebarFontColor = "#2d2e32"; //-------------------Old
export const sidebarFontColor = "#ececec"; //-------------------New

export const sidebarSelectedFontColor = "#fff"; //-------------------Old
// export const sidebarSelectedFontColor = "#000"; //-------------------New

// export const sidebarIconColor = "#e970bf"; //-------------------Old
export const sidebarIconColor = "#ececec"; //-------------------New

// export const sidebarSelectedMenuBackground =
//   "linear-gradient(45deg,#ed76b3,#fbb1a5)"; //-------------------Old
export const sidebarSelectedMenuBackground =
  "linear-gradient(45deg,#ed6aa5,#d0cdf1)"; //-------------------New

// export const cardBackground = "#313133"; //-------------------Old
export const cardBackground = "#313133"; //-------------------New
