import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { adminMenus, defaultMenus, testArr } from "../config";
import PageRouter from "../routes";
import NewUser from "./new-user";
import PageLoader from "../components/page-loader";
import { message } from "antd";

const HeaderASINWiser = React.lazy(() => import("../containers/layout/header"));
const SidebarASINWiser = React.lazy(() =>
  import("../containers/layout/sidebar")
);
const Footer = React.lazy(() => import("./layouts/footer"));

export default function (props) {
  const { CheckSubscription, fakeActionCheckSubscription } = props;
  const location = useLocation();
  const history = useHistory();
  const [hide, setHide] = useState(false);
  const [moveToLogin, setMoveToLogin] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [hideMenus, setHideMenus] = useState(false);
  const [moveToDashboard, setMoveToDashboard] = useState(false);
  const [bootStatus, setBootStatus] = useState(true);
  const [bootStatusFail, setBootStatusFail] = useState(false);

  const [amazonIdIs, setAmazonIdIs] = useState(false);

  const CheckSubscriptionRes = useSelector(
    (state) => state.CheckSubscription?.CheckSubscriptionResponse || {}
  );

  if (
    localStorage.getItem("user") == "undefined" ||
    !localStorage.getItem("user")
  ) {
    localStorage.clear();
    history.replace("/login");
  }
  const [userData, setUserData] = useState(
    localStorage.getItem("user") == "undefined"
      ? {}
      : JSON.parse(localStorage.getItem("user"))
  );
  window.updateProfile = (e) => {
    setUserData(e);
  };

  const expandSidebar = () => {
    if (991 >= window.innerWidth) {
      const element = document.getElementById("kt_aside");
      const commonFix = "aside pt-7 pb-4 pb-lg-7 pt-lg-10 drawer drawer-start";
      if (!element) return;
      element.classList = hide
        ? `${commonFix} drawer drawer-start drawer-on`
        : commonFix;
    }

    if (hide) {
      const node = document.createElement("div");

      node.onclick = () => setHide(false);
      node.style.zIndex = 109;
      document.getElementById("root").appendChild(node);
    } else {
      const node = document.getElementById("drawer-overlay");
      if (node !== null) {
        node.remove();
      }
    }
  };

  const checkWidth = () => {
    setHideMenus(690 > window.innerWidth);
    const element = document.getElementById("kt_aside");
    const commonFix = "aside pt-7 pb-4 pb-lg-7 pt-lg-10";
    if (!element) return;
    element.classList =
      991 >= window.innerWidth ? `${commonFix} drawer drawer-start` : commonFix;
    element.style.width = 991 >= window.innerWidth ? "250px" : "300px";
    const node = document.getElementById("drawer-overlay");
    if (node !== null) {
      node.remove();
    }
  };
  // console.log(bootStatus);
  useEffect(() => {
    console.log("mount");
    CheckSubscription();
    window.addEventListener("resize", (e) => {
      checkWidth();
    });
    window.addEventListener("storage", (e) => {
      const { adminData, user, userType, menusList } = e?.storageArea;
      if (localStorage.getItem("user") == "undefined") {
        localStorage.clear();
        history.replace("/login");
      }
      if (adminData) {
        const abc = JSON.parse(menusList);

        const menuName = location.pathname?.split("/");

        if (menuName.length > 0) {
          setTimeout(() => {
            const finedMenuIndex = abc?.findIndex((d) =>
              d?.includes(menuName?.[1])
            );

            if (finedMenuIndex === -1) {
              return history.replace("/dashboard");
            }
          }, 2000);
        }
      } else {
        // return history.replace("/manage-seller-users");

        const abc = JSON.parse(menusList);
        const menuName = location.pathname?.split("/");
        if (menuName.length > 0) {
          setTimeout(() => {
            const finedMenuIndex = abc?.findIndex((d) =>
              d?.includes(menuName?.[1])
            );
            console.log(finedMenuIndex, "finedMenuIndex");

            if (finedMenuIndex === -1) {
              return history.replace("/manage-seller-users");
            }
          }, 2000);
        }
      }

      // if (!user || user == "undefined") {
      //   localStorage.clear();
      //   setMoveToLogin(true);
      // }

      // if (parseInt(userType) !== 1 && adminData) {
      //   // history.replace("/dashboard"); // comment because when click on link then page replace with ('/dashbord)
      // }

      // if (adminDataLocal) {
      //   adminDataLocal = JSON.parse(adminDataLocal);
      //   localStorage.setItem("user", adminDataLocal.data);
      //   localStorage.setItem(
      //     "userType",
      //     JSON.parse(adminDataLocal?.data)?.user_type
      //   );
      //   localStorage.setItem(
      //     "menusList",
      //     JSON.stringify(
      //       JSON.parse(adminDataLocal?.data)?.user_type == 1
      //         ? adminMenus
      //         : testArr
      //     )
      //   );
      //   localStorage.setItem("token", adminDataLocal.token);
      //   setUserData(JSON.parse(adminDataLocal.data));
      //   setAmazonIdIs(false);
      //   localStorage.removeItem("adminData");
      // }
    });
    return () => {
      console.log("unmount");
      window.removeEventListener("resize", () => {});
      window.removeEventListener("storage", (e) => {});
    };
  }, []);

  const isAddedInAdmin = ["data-scheduler", "application-log"];

  const checkPlan = () => {
    if (localStorage.getItem("userType") === 1) {
      return adminMenus;
      // }
    }
    if (localStorage.getItem("adminData")) {
      return testArr;
    } else {
      return [
        ...CheckSubscriptionRes?.data?.services?.map((d) => d?.slug),
        ...defaultMenus,
      ];
    }

    // return testArr;
  };

  useEffect(() => {
    if (CheckSubscriptionRes?.status === true) {
      setBootStatus(false);
      localStorage.setItem("plan", CheckSubscriptionRes?.plan);
      localStorage.setItem(
        "subscription_status",
        CheckSubscriptionRes?.data?.subscription_status
      );
      localStorage.setItem("menusList", JSON.stringify(checkPlan()));
      fakeActionCheckSubscription("CheckSubscriptionResponse");
    } else if (CheckSubscriptionRes?.status === false) {
      setBootStatus(false);
      setBootStatusFail(true);
      message.error(CheckSubscriptionRes?.message);
      fakeActionCheckSubscription("CheckSubscriptionResponse");
    }
  }, [CheckSubscriptionRes]);

  useEffect(() => {
    expandSidebar();
  }, [hide]);

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth < 992) {
        setCollapsed(true);
        setHide(true);
      } else {
        setCollapsed(false);
        setHide(false);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const backToAdmin = () => {
    let adminDataLocal = localStorage.getItem("adminData");
    if (adminDataLocal) {
      adminDataLocal = JSON.parse(adminDataLocal);
      localStorage.setItem("user", adminDataLocal.data);
      localStorage.setItem(
        "userType",
        JSON.parse(adminDataLocal?.data)?.user_type
      );
      localStorage.setItem(
        "menusList",
        JSON.stringify(
          JSON.parse(adminDataLocal?.data)?.user_type == 1
            ? adminMenus
            : testArr
        )
      );
      localStorage.setItem("token", adminDataLocal.token);
      setUserData(JSON.parse(adminDataLocal.data));
      setAmazonIdIs(false);
      localStorage.removeItem("adminData");
    }
  };

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
    if (window.location.pathname === "/login") {
      return setMoveToLogin(true);
    }
    if (
      JSON.parse(localStorage.getItem("user") || {})?.is_user_credential == 1 ||
      JSON.parse(localStorage.getItem("userType") || 1) == 1
    ) {
      setAmazonIdIs("close");
    } else {
      if (
        location?.pathname?.includes("/spapi-callback") ||
        location?.pathname?.includes("/setting") ||
        location?.pathname?.includes("/callbackads")
      ) {
        setAmazonIdIs("close");
      } else {
        if (
          location?.pathname?.includes("sales-analytics") ||
          location?.pathname?.includes("listing-optimizer") ||
          location?.pathname?.includes("inventory")
        ) {
          setAmazonIdIs(true);
        }
      }
    }
    return () => {};
  }, [location?.pathname]);

  const checkLocal = (type) => {
    return localStorage.getItem(type);
  };

  if (bootStatus) {
    return (
      <div>
        <PageLoader />
      </div>
    );
  }
  if (!bootStatus && bootStatusFail) {
    return (
      <div>
        <div className="d-flex justify-content-center align-items-center">
          {/* <img
            src="/media/Questions-rafiki.svg"
            style={{ height: "40%", width: "40%" }}
          /> */}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <button
            style={{ width: "130px" }}
            className="btn btn-primary"
            onClick={() => {
              setBootStatusFail(false);
              setBootStatus(true);
              CheckSubscription();
            }}
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (
    !checkLocal("user") ||
    !checkLocal("userType") ||
    !checkLocal("token") ||
    !checkLocal("menusList")
  ) {
    localStorage.clear();
    history.replace("/login");
  }
  if (!localStorage.getItem("userType") || moveToLogin === true) {
    localStorage.clear();
    history.replace("/login");
    return;
  }
  if (moveToDashboard === true) {
    // return <Redirect to={"/dashboard"} />;
  }

  const GetModules = () =>
    localStorage.getItem("userType") == 2 ? false : true;

  return (
    <>
      <div
        className="fade-in d-flex flex-column flex-root"
        style={{ height: "100%" }}
      >
        <div
          className="page d-flex flex-row flex-column-fluid"
          style={{ height: "100%" }}
        >
          <div
            className="fadeInLeft"
            style={{
              zIndex: window.innerWidth >= 992 ? "999" : "1000",
              padding: hideMenus ? "5px" : "12px",
            }}
          >
            <SidebarASINWiser
              logout={() => setMoveToLogin(true)}
              user={userData || {}}
              hideMenus={hideMenus}
              collapsed={collapsed}
              userType={GetModules()}
              setHideMenus={setHideMenus}
              setCollapsed={() => setCollapsed(!collapsed)}
              bootStatus={bootStatus}
              {...props}
            />
          </div>
          <div className=" d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <HeaderASINWiser
              backToAdmin={() => backToAdmin()}
              backToUser={setUserData}
              hideMenus={hideMenus}
              userData={userData}
              setHideMenus={setHideMenus}
              setCollapsed={() => setCollapsed(!collapsed)}
              collapsed={collapsed}
              {...props}
            />
            <div className=" d-flex flex-column flex-row-fluid">
              <div className=" flex-column flex-column-fluid">
                {bootStatus ? (
                  <div>
                    <h5>Loading</h5>
                  </div>
                ) : (
                  <PageRouter authStatus={GetModules()} {...props} />
                )}
              </div>
              <Footer {...props} />
            </div>
          </div>
        </div>
      </div>
      <NewUser
        show={amazonIdIs}
        close={() => {
          setAmazonIdIs("close");
        }}
      />
    </>
  );
}
