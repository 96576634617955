export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "SAVE_ADVANCED_REPRICING_RULE_SUCCESS":
    case "SAVE_ADVANCED_REPRICING_RULE_ERROR":
      return {
        ...state,
        SaveAdvancedRepricingRuleResponse: action.updatePayload,
      };

    case "FAKE_ACTION_ADVANCED_REPRICING_RULE":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
