export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "CHECK_SUBSCRIPTION_SUCCESS":
    case "CHECK_SUBSCRIPTION_ERROR":
      return {
        ...state,
        CheckSubscriptionResponse: action.updatePayload,
      };

    case "FAKE_ACTION_CHECK_SUBSCRIPTION":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
