export default (state, action) => {
    if (typeof state === "undefined") {
      return [];
    }
    switch (action.type) {
      case "GET_REPRICING_RULE_LIST_SUCCESS":
      case "GET_REPRICING_RULE_LIST_ERROR":
        return {
          ...state,
          GetRepricingRuleListResponse: action.updatePayload,
        };
      case "ADD_REPRICING_RULE_SUCCESS":
      case "ADD_REPRICING_RULE_ERROR":
        return {
          ...state,
          AddRepricingRuleResponse: action.updatePayload,
        };
      case "UPDATE_REPRICING_RULE_SUCCESS":
      case "UPDATE_REPRICING_RULE_ERROR":
        return {
          ...state,
          UpdateRepricingRuleResponse: action.updatePayload,
        };
      case "DELETE_REPRICING_RULE_SUCCESS":
      case "DELETE_REPRICING_RULE_ERROR":
        return {
          ...state,
          DeleteRepricingRuleResponse: action.updatePayload,
        };
      case "FAKE_ACTION_REPRICING_RULE":
        return { ...state, [action?.state]: {} };
      default:
        return state;
    }
  };
  