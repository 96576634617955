import React from "react";
import asyncComponent from "../../core/helper/asyncComponent";
const Dashboard = React.lazy(() => import("../../containers/dashboard"));
const AddRules = React.lazy(() =>
  import("../../modules/pages/repricing/amazon-repricing-rules/lib/addRules")
);
const ExpensesASINWiser = React.lazy(() => import("../../containers/expenses"));
// const ViewPaymentsWiser = React.lazy(() =>
//   import("../../modules/pages/admin/view-payments")
// );

const ItemMasterASINWiser = React.lazy(() =>
  import("../../containers/item-master")
);
const SqsMessagesWiser = React.lazy(() =>
  import("../../containers/application-logs/sqs-messages")
);
const RepricingNotificationWiser = React.lazy(() =>
  import("../../containers/application-logs/repricing-notification")
);
const AppConfiguration_ = React.lazy(() =>
  import("../../containers/admin/setting/app-configuration")
);
const AdminScheduler_ = React.lazy(() =>
  import("../../containers/admin/setting/admin-scheduler")
);
const UserFeedbackWiser = React.lazy(() =>
  import("../../containers/admin/user-feedback")
);
const PlanningInventoryASINWiser = React.lazy(() =>
  import("../../containers/manage-reports/planning-inventory")
);
const StrandedInventoryASINWiser = React.lazy(() =>
  import("../../containers/manage-reports/stranded-inventory")
);

const KeywordTrackerASINWiser = React.lazy(() =>
  import("../../containers/keyword-tracker")
);
const ListingOptimizerASINWiser = React.lazy(() =>
  import("../../containers/listing-optimizer")
);
const EstimatedSalesASINWiser = React.lazy(() =>
  import("../../containers/estimated-sales")
);
const InventoryASINWiser = React.lazy(() =>
  import("../../containers/Inventory")
);
const InventoryDetailsASIN = React.lazy(() =>
  import("../../containers/Inventory/details")
);
const AmazonRepricingRulesASINWiser = React.lazy(() =>
  import("../../containers/repricing/amazon-repricing-rules")
);
const CompetitorsASINWiser = React.lazy(() =>
  import("../../containers/repricing/competitors")
);
const ProductsASINWiser = React.lazy(() =>
  import("../../containers/repricing/products")
);
const CompetitorResearch = React.lazy(() =>
  import("../../containers/competitor-research")
);
const MasterTableBrand = React.lazy(() =>
  import("../../containers/master-tables/brands")
);
const MasterTableSkuData = React.lazy(() =>
  import("../../containers/master-tables/sku-data")
);
const ProfitabilityCalculator = React.lazy(() =>
  import("../../containers/profitability-calculator")
);
const BulkProfitabilityCalculator = React.lazy(() =>
  import("../../containers/bulk-profitability-calculator")
);
const BulkDetails = React.lazy(() =>
  import("../../containers/bulk-profitability-calculator/lib/details")
);

const DataSchedulerASINWiser = React.lazy(() =>
  import("../../containers/data-scheduler")
);
const InventoryForecasting = React.lazy(() =>
  import("../../containers/inventory-forecasting")
);
const InventoryManagement = React.lazy(() =>
  import("../../containers/inventory-management")
);
const ManageSellerUser = React.lazy(() =>
  import("../../containers/manageSellerUser")
);
const WholesaleSupplierDirectory = React.lazy(() =>
  import("../../containers/wholesale-supplier-directory")
);
const WholesaleListSuppliers = React.lazy(() =>
  import(
    "../../containers/wholesale-supplier-directory/lib/wholesale-list-suppliers"
  )
);
const SalesAnalyticsAsin = React.lazy(() =>
  import("../../containers/sales-analytics")
);
const ProductResearch = React.lazy(() =>
  import("../../containers/product-research")
);
const ProductView = React.lazy(() =>
  import("../../containers/product-research/lib/details")
);
const PricingPlans = React.lazy(() => import("../../containers/pricing"));
const ManageSubscription = React.lazy(() => import("../../containers/pricing"));
const PaymentHistory = React.lazy(() =>
  import("../../containers/payment-history")
);
// const Profile = React.lazy(() => import("../../containers/profile"));
const AnalyticsByBrand = React.lazy(() =>
  import("../../containers/competitor-search-analytics/analytics-by-brand")
);
const BrandRevenueList = React.lazy(() =>
  import("../../containers/brand-revenue-list")
);
const AdminManageUsers = React.lazy(() =>
  import("../../containers/admin/ManageUsers")
);
const AdminCategories = React.lazy(() =>
  import("../../containers/admin/Categories")
);
const AdminCentralLog = React.lazy(() =>
  import("../../containers/admin/CentralLog")
);
const UserCentralLog = React.lazy(() =>
  import("../../containers/application-logs/central")
);
const UserSystemLog = React.lazy(() =>
  import("../../containers/application-logs/system")
);
const AdminSystemLogs = React.lazy(() =>
  import("../../containers/admin/SystemLogs")
);
const ArchivedInventory = React.lazy(() =>
  import("../../containers/manage-reports/archived-inventory")
);
const FinanceData = React.lazy(() =>
  import("../../containers/manage-reports/finance-data")
);
const OrdersData = React.lazy(() =>
  import("../../containers/manage-reports/orders-data")
);
const ProductData = React.lazy(() =>
  import("../../containers/manage-reports/product-data")
);
const ReimbursementsReport = React.lazy(() =>
  import("../../containers/manage-reports/reimbursements_report")
);
const ReservedInventory = React.lazy(() =>
  import("../../containers/manage-reports/reserved-inventory")
);
const ReturnOrderData = React.lazy(() =>
  import("../../containers/manage-reports/return-order-data")
);
const Setting = React.lazy(() => import("../../containers/setting"));
const MyList = React.lazy(() => import("../../containers/my-list"));
const BrandRule_ = React.lazy(() =>
  import("../../containers/admin/brand-rules")
);
const SpApiReports = React.lazy(() =>
  import("../../containers/manage-reports/sp-api-reports/")
);
const ReviewAnalysis = React.lazy(() =>
  import("../../containers/review-analysis")
);
const Reimbursement = React.lazy(() =>
  import("../../containers/reimbursement")
);
const MasterTableSupplier = React.lazy(() =>
  import("../../containers/master-tables/supplier-setup")
);
const InventoryASINDetailsASINWiser = React.lazy(() =>
  import("../../containers/Inventory/asin-details")
);
const AlgorithmicRepricerProductASINWiser = React.lazy(() =>
  import("../../containers/algorithmic-repricer/product-repricing")
);
const AlgorithmicRepricingRuleASINWiser = React.lazy(() =>
  import("../../containers/algorithmic-repricer/repricing-rules")
);
const PoListASINWiser = React.lazy(() => import("../../containers/po-list"));

const AdvancedRepricingRuleASINWiser = React.lazy(() =>
  import("../../containers/repricing/advanced-repricing-rule")
);
const RepricingProductASINWiser = React.lazy(() =>
  import("../../containers/repricing/repricing-product")
);
export const userRoutersList = [
  {
    path: "/",
    key: "dashboard",
    name: Dashboard,
  },
  {
    path: "/dashboard",
    key: "dashboard",
    name: Dashboard,
  },
  // {
  //   path: "/expenses",
  //   key: "expenses",
  //   name: ExpensesASINWiser,
  // },
  {
    path: "/item-master",
    key: "item-master",
    name: ItemMasterASINWiser,
  },
  {
    path: "/listing-optimizer/keyword-tracker",
    key: "keyword-tracker",
    name: KeywordTrackerASINWiser,
  },
  {
    path: "/listing-optimizer",
    key: "listing-optimizer",
    name: ListingOptimizerASINWiser,
  },
  {
    path: "/inventory/replenishment",
    key: "inventory",
    name: InventoryASINWiser,
  },
  {
    path: "/inventory/polist",
    key: "inventory",
    name: PoListASINWiser,
  },
  {
    path: "/asin-details",
    key: "inventory",
    name: InventoryASINDetailsASINWiser,
  },
  {
    path: "/inventory/Reconciliation",
    key: "inventory",
    name: InventoryDetailsASIN,
  },
  {
    path: "/master-table/brands",
    key: "master-table",
    name: MasterTableBrand,
  },
  {
    path: "/master-table/sku-data",
    key: "master-table",
    name: MasterTableSkuData,
  },
  {
    path: "/master-table/expenses",
    key: "master-table",
    name: ExpensesASINWiser,
  },
  {
    path: "/master-table/supplier-setup",
    key: "master-table",
    name: MasterTableSupplier,
  },
  {
    path: "/inventory-management",
    key: "inventory-management",
    name: InventoryManagement,
  },
  {
    path: "/sp-api-reports",
    key: "sp-api-reports",
    name: SpApiReports,
  },
  // {
  //   path: "/sp-api-reports/archived-inventory",
  //   key: "sp-api-reports",
  //   name: ArchivedInventory,
  // },
  // {
  //   path: "/sp-api-reports/finance-data",
  //   key: "sp-api-reports",
  //   name: FinanceData,
  // },
  // {
  //   path: "/sp-api-reports/orders-data",
  //   key: "sp-api-reports",
  //   name: OrdersData,
  // },
  // {
  //   path: "/sp-api-reports/product-data",
  //   key: "sp-api-reports",
  //   name: ProductData,
  // },
  {
    path: "/repricing/products",
    key: "repricing",
    name: ProductsASINWiser,
  },
  {
    path: "/repricing/amazon-repricing-rules",
    key: "repricing",
    name: AmazonRepricingRulesASINWiser,
  },
  {
    path: "/repricing/advanced-repricing-rules",
    key: "repricing",
    name: AdvancedRepricingRuleASINWiser,
  },
  {
    path: "/repricing/repricing-product",
    key: "repricing",
    name: RepricingProductASINWiser,
  },
  {
    path: "/repricing/competitors",
    key: "repricing",
    name: CompetitorsASINWiser,
  },
  // {
  //   path: "/sp-api-reports/reimbursements",
  //   key: "sp-api-reports",
  //   name: ReimbursementsReport,
  // },
  // {
  //   path: "/sp-api-reports/reserved-inventory",
  //   key: "sp-api-reports",
  //   name: ReservedInventory,
  // },
  // {
  //   path: "/sp-api-reports/return-order-data",
  //   key: "sp-api-reports",
  //   name: ReturnOrderData,
  // },
  // {
  //   path: "/sp-api-reports/planning-inventory",
  //   key: "sp-api-reports",
  //   name: PlanningInventoryASINWiser,
  // },
  // {
  //   path: "/sp-api-reports/stranded-inventory",
  //   key: "sp-api-reports",
  //   name: StrandedInventoryASINWiser,
  // },
  {
    path: "/repricing/amazon-repricing-rules/add-rules",
    key: "repricing",
    name: AddRules,
  },
  {
    path: "/inventory-forecasting",
    key: "inventory-forecasting",
    name: InventoryForecasting,
  },
  {
    path: "/data-scheduler",
    key: "data-scheduler",
    name: DataSchedulerASINWiser,
  },
  {
    path: "/competitor-research",
    key: "competitor-research",
    name: CompetitorResearch,
  },
  {
    path: "/profitability-calculator",
    key: "profitability-calculator",
    name: ProfitabilityCalculator,
  },
  {
    path: "/bulk-profitability-calculator",
    key: "bulk-profitability-calculator",
    name: BulkProfitabilityCalculator,
  },
  {
    path: "/bulk-profitability-calculator/Details",
    key: "bulk-profitability-calculator",
    name: asyncComponent(BulkDetails),
  },
  {
    path: "/sales-analytics",
    key: "sales-analytics",
    name: SalesAnalyticsAsin,
  },
  {
    path: "/manage-company-employee",
    key: "manage-company-employee",
    name: ManageSellerUser,
  },
  {
    path: "/wholesale-supplier-directory",
    key: "wholesale-supplier-directory",
    name: WholesaleSupplierDirectory,
  },
  {
    path: "/wholesale-supplier-list",
    key: "wholesale-supplier-directory",
    name: WholesaleListSuppliers,
  },
  {
    path: "/product-research",
    key: "product-research",
    name: ProductResearch,
  },
  {
    path: "/competitor-search-analytics/analytics-by-brand",
    key: "competitor-search-analytics",
    name: AnalyticsByBrand,
  },
  {
    path: "/product-research/details",
    key: "product-research",
    name: ProductView,
  },
  {
    path: "/pricing-plans",
    key: "pricing-plans",
    name: PricingPlans,
  },
  {
    path: "/payment-history",
    key: "payment-history",
    name: PaymentHistory,
  },
  // {
  //   path: "/profile",
  //   key: "profile",
  //   name: Profile,
  // },
  {
    path: "/brand-wise-analytics",
    key: "product-research",
    name: BrandRevenueList,
  },
  {
    path: "/my-list",
    key: "product-research",
    name: MyList,
  },
  {
    path: "/my-list",
    key: "competitor-research",
    name: MyList,
  },
  {
    path: "/application-logs/central-log-system",
    key: "application-logs",
    name: UserCentralLog,
  },
  {
    path: "/application-logs/sqs-messages",
    key: "application-logs",
    name: SqsMessagesWiser,
  },
  {
    path: "/application-logs/repricing-notification",
    key: "application-logs",
    name: RepricingNotificationWiser,
  },
  {
    path: "/application-logs/system-event-log",
    key: "application-logs",
    name: UserSystemLog,
  },
  {
    path: "/setting",
    key: "setting",
    name: Setting,
  },
  {
    path: "/spapi-callback",
    key: "setting",
    name: Setting,
  },
  {
    path: "/callbackads",
    key: "setting",
    name: Setting,
  },
  {
    path: "/feedback-automation",
    key: "feedback-automation",
    name: ReviewAnalysis,
  },
  { path: "/reimbursement", key: "reimbursement", name: Reimbursement },
  {
    path: "/algorithmic-repricer/product-repricing",
    key: "algorithmic-repricer",
    name: AlgorithmicRepricerProductASINWiser,
  },
  {
    path: "/algorithmic-repricer/repricing-rules",
    key: "algorithmic-repricer",
    name: AlgorithmicRepricingRuleASINWiser,
  },
];

export const adminRoutersList = [
  {
    path: "/",
    key: "dashboard",
    name: Dashboard,
  },
  {
    path: "/dashboard",
    key: "dashboard",
    name: Dashboard,
  },
  // {
  //   path: "/view-payments",
  //   key: "view-payments",
  //   name: ViewPaymentsWiser,
  // },
  {
    path: "/brand-rules",
    key: "brand-rules",
    name: BrandRule_,
  },
  {
    path: "/setting/app-configuration",
    key: "app-configuration",
    name: AppConfiguration_,
  },
  {
    path: "/setting/admin-scheduler",
    key: "admin-scheduler",
    name: AdminScheduler_,
  },
  {
    path: "/user-feedback",
    key: "user-feedback",
    name: UserFeedbackWiser,
  },
  {
    path: "/estimated-sales",
    key: "estimated-sales",
    name: EstimatedSalesASINWiser,
  },
  {
    path: "/manage-seller-users",
    key: "manage-seller-users",
    name: AdminManageUsers,
  },
  {
    path: "/categories",
    key: "categories",
    name: AdminCategories,
  },
  {
    path: "/application-logs/central-log-system",
    key: "application-logs",
    name: AdminCentralLog,
  },
  {
    path: "/application-logs/system-event-log",
    key: "application-logs",
    name: AdminSystemLogs,
  },
  {
    path: "/wholesale-supplier-directory",
    key: "wholesale-supplier-directory",
    name: WholesaleSupplierDirectory,
  },
  {
    path: "/manage-subscription",
    key: "manage-subscription",
    name: ManageSubscription,
  },
  {
    path: "/wholesale-supplier-list",
    key: "wholesale-supplier-list",
    name: WholesaleListSuppliers,
  },
];
