export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_REIMBURSEMENT_INVENTORY_LIST_SUCCESS":
    case "GET_REIMBURSEMENT_INVENTORY_LIST_ERROR":
      return {
        ...state,
        GetReimbursementInventoryListResponse: action.updatePayload,
      };

    case "GET_REIMBURSEMENT_SHIPMENT_LIST_SUCCESS":
    case "GET_REIMBURSEMENT_SHIPMENT_LIST_ERROR":
      return {
        ...state,
        GetReimbursementShipmentListResponse: action.updatePayload,
      };

    case "GET_REIMBURSEMENT_INVENTORY_DETAILS_SUCCESS":
    case "GET_REIMBURSEMENT_INVENTORY_DETAILS_ERROR":
      return {
        ...state,
        GetReimbursementInventoryDetailsResponse: action.updatePayload,
      };

    case "GET_REIMBURSEMENT_SHIPMENT_DETAILS_SUCCESS":
    case "GET_REIMBURSEMENT_SHIPMENT_DETAILS_ERROR":
      return {
        ...state,
        GetReimbursementShipmentDetailsResponse: action.updatePayload,
      };

    case "SAVE_REIMBURSEMENT_INVENTORY_NOTES_SUCCESS":
    case "SAVE_REIMBURSEMENT_INVENTORY_NOTES_ERROR":
      return {
        ...state,
        SaveReimbursementInventoryNotesResponse: action.updatePayload,
      };

    case "GET_INVENTORY_CASE_LIST_SUCCESS":
    case "GET_INVENTORY_CASE_LIST_ERROR":
      return {
        ...state,
        GetInventoryCaseListResponse: action.updatePayload,
      };

    case "ADD_NEW_INVENTORY_CASE_SUCCESS":
    case "ADD_NEW_INVENTORY_CASE_ERROR":
      return {
        ...state,
        AddNewInventoryCaseResponse: action.updatePayload,
      };

    case "FAKE_ACTION_REIMBURSEMENT":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
