export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_MARKETPLACES_SUCCESS":
    case "GET_MARKETPLACES_ERROR":
      return {
        ...state,
        GetMarketplacesResponse: action.updatePayload,
      };
    case "GET_USER_CREDENTIALS_LIST_SUCCESS":
    case "GET_USER_CREDENTIALS_LIST_ERROR":
      return {
        ...state,
        GetUserCredentialsResponse: action.updatePayload,
      };
    case "UPDATE_PROFILE_SETTING_SUCCESS":
    case "UPDATE_PROFILE_SETTING_ERROR":
      return {
        ...state,
        UpdateProfileSettingResponse: action.updatePayload,
      };
    case "CHANGE_PASSWORD_SETTING_SUCCESS":
    case "CHANGE_PASSWORD_SETTING_ERROR":
      return {
        ...state,
        ChangePasswordSettingResponse: action.updatePayload,
      };

    case "SAVE_VAT_SETTING_SUCCESS":
    case "SAVE_VAT_SETTING_ERROR":
      return {
        ...state,
        SaveVATSettingResponse: action.updatePayload,
      };

    case "GET_MARKETPLACES_VAT_RATE_LIST_SUCCESS":
    case "GET_MARKETPLACES_VAT_RATE_LIST_ERROR":
      return {
        ...state,
        GetVatRateListResponse: action.updatePayload,
      };

    case "GET_COMMON_VAT_SETTING_SUCCESS":
    case "GET_COMMON_VAT_SETTING_ERROR":
      return {
        ...state,
        GetCommonVatSettingResponse: action.updatePayload,
      };

    case "SAVE_COMMON_VAT_SETTING_SUCCESS":
    case "SAVE_COMMON_VAT_SETTING_ERROR":
      return {
        ...state,
        SaveCommonVatSettingResponse: action.updatePayload,
      };

    case "SAVE_ROW_VAT_SETTING_SUCCESS":
    case "SAVE_ROW_VAT_SETTING_ERROR":
      return {
        ...state,
        SaveRowVatSettingResponse: action.updatePayload,
      };

    case "FAKE_ACTION_SETTING":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
