import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import PageLoader from "./components/page-loader";
import asyncComponent from "./core/helper/asyncComponent";
import Login from "./containers/auth";
import CreateAccount from "./containers/auth/lib";
import MailSentASINWiser from "./containers/auth/lib/mail-sent";
import ResetPasswordASINWiser from "./containers/auth/lib/reset-password";
import Pages from "./modules/Page";
import UserActiveASINWiser from "./containers/auth/user-active";
import PageJsASINWiser from "./containers";
// const Login = React.lazy(() => import("./containers/auth"));
// const CreateAccount = React.lazy(() => import("./containers/auth/lib"));
// const MailSentASINWiser = React.lazy(() =>
//   import("./containers/auth/lib/mail-sent")
// );
// const ResetPasswordASINWiser = React.lazy(() =>
//   import("./containers/auth/lib/reset-password")
// );
// const Pages = React.lazy(() => import("./modules/Page"));
// const UserActiveASINWiser = React.lazy(() =>
//   import("./containers/auth/user-active")
// );

function AppRoute(props) {
  return (
    <BrowserRouter history={props.history}>
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route path="/create-account" component={CreateAccount} exact />
          <Route path="/forgot-password" component={MailSentASINWiser} exact />
          <Route path="/user/activate" component={UserActiveASINWiser} exact />
          <Route
            path="/reset-password"
            component={ResetPasswordASINWiser}
            exact
          />
          <Route path="/" component={PageJsASINWiser} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppRoute;
