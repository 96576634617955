import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ASINTooltip from "../../../../components/tooltip";
import { Tag } from "antd";

export default function (props) {
  const { show, close, data } = props;
  return (
    <Modal show={show} onHide={close} size="lg" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{data?.CompanyName || ""}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="fadeInRight">
        <div className="jss3549">
          <div className="d-flex align-items-center mb-5 whole_sale_supplier_modal">
            <span
              href
              className="fw-bold d-flex align-items-center"
              style={{ fontSize: "14px" }}
            >
              <span className="fs-4">Category&nbsp;-&nbsp;</span>
              <Tag color="cyan">{data?.CategoryName}</Tag>
            </span>
            <span
              href
              className=" fw-bold d-flex align-items-center"
              style={{ fontSize: "14px", marginLeft: "20px" }}
            >
              <span className="fs-4">Supplier Name&nbsp;-&nbsp;</span>
              <Tag color="cyan">{data?.CompanyName}</Tag>
            </span>
          </div>

          <p>
            <b>Brand :</b>{" "}
            <ASINTooltip rule row={3}>
              {data?.Brand}
            </ASINTooltip>
          </p>
          <p>
            <b>Company Profile :</b> -
          </p>

          <p>
            {" "}
            <b>Telephone :</b> {data?.ContactNumber}
          </p>
          <p>
            {" "}
            <b>Website :</b>{" "}
            <Link
              target="_blank"
              onClick={() => window.open(data?.Website || "")}
            >
              {data?.Website}
            </Link>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light-danger" onClick={close}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
