import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Page from "../modules/Page";
import {
  CheckSubscription,
  fakeActionCheckSubscription,
} from "../redux/modules/pageJs.action";

const mapStateToProps = (state) => ({
  CheckSubscriptionResponse: state.CheckSubscription.CheckSubscriptionResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      CheckSubscription,
      fakeActionCheckSubscription,
    },
    dispatch
  );

const PageJsASINWiser = connect(mapStateToProps, mapDispatchToProps)(Page);

export default PageJsASINWiser;
