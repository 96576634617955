export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_REPORT_REIMBURSEMENTS_LIST_SUCCESS":
    case "GET_REPORT_REIMBURSEMENTS_LIST_ERROR":
      return {
        ...state,
        GetReportReimbursementsDataListResponse: action.updatePayload,
      };
    case "FAKE_ACTION_MANAGE_REPORTS_REIMBURSEMENTS_REPORT_DATA":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
