export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_PROFITABILITY_CALCULATOR_SUCCESS":
    case "GET_PROFITABILITY_CALCULATOR_ERROR":
      return {
        ...state,
        ProfitabilityCalculatorResponse: action.updatePayload,
      };
    case "GET_PROFITABILITY_CALCULATOR_KEEPA_GRAPH_SUCCESS":
    case "GET_PROFITABILITY_CALCULATOR_KEEPA_GRAPH_ERROR":
      return {
        ...state,
        ProfitabilityCalculatorKeepaGraphResponse: action.updatePayload,
      };
    case "FAKE_ACTION_PROFITABILITY_CALCULATOR":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
