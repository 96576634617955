import Icons from "../../../components/icons";
import ASINLink from "../../../components/router-link";
const LinksMenu = (label, key, children, path) => {
  return (
    <ASINLink
      id={Math.random()}
      title=""
      // target={"reimbursement" === key ? "blank" : ""}
      to={{
        pathname:
          "reimbursement" === key
            ? path?.[0]
            : `${children ? "/" + children : ""}/${key}`,
        state: {},
      }}
    >
      {label}
    </ASINLink>
  );
};
function getItem(label, key, icon, children, type, path) {
  return {
    title: window.innerWidth >= 992 ? label : "",
    key,
    icon,
    children: Array.isArray(children) ? children : null,
    label:
      children && Array.isArray(children)
        ? label
        : LinksMenu(label, key, children, path),
    type,
  };
}
export const convertSidebarLinks = (list, mode) => {
  return list
    ?.sort(function (a, b) {
      return a?.index?.[0]?.[mode] - b?.index?.[0]?.[mode];
    })
    ?.map((d) => {
      if (d?.children && d?.children?.length !== 0) {
        return getItem(
          d?.title,
          d?.name,
          d?.icon,
          d?.children?.map((c) => {
            return getItem(c.title, c.name, c.icon, c.parent);
          })
        );
      }
      return getItem(d.title, d.name, d.icon, null, null, d?.path);
    });
};
