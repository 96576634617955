import React from "react";
import { Link } from "react-router-dom";
const ASINLink = (props) => {
  const { to, children, id, target, ...rest } = props;
  let obj = { ...to?.state };

  return (
    <>
      <Link
        id={id}
        target={target}
        to={
          typeof to === "string"
            ? to
            : {
                pathname: to?.pathname,
                search: Object.entries(obj)
                  ?.map((d, i) => {
                    return `${i === 0 ? "?" : "&"}${d[0]}=${d[1] || ""}`;
                  })
                  ?.join(""),
              }
        }
        {...rest}
      >
        {children}
      </Link>
    </>
  );
};

export default ASINLink;
