export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_CATEGORIES_LIST_SUCCESS":
    case "GET_CATEGORIES_LIST_ERROR":
      return {
        ...state,
        GetCategoriesListResponse: action.updatePayload,
      };
    case "CATEGORIES_UPDATE_STATUS_SUCCESS":
    case "CATEGORIES_UPDATE_STATUS_ERROR":
      return {
        ...state,
        CategoriesUpdateStatusResponse: action.updatePayload,
      };
    case "FAKE_ACTION_CATEGORIES":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
