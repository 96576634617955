import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import Loading from "../../../../components/loading";
import NoData from "../../../../components/no-data";
import Pagination from "../../../../components/pagination";
import ASINLink from "../../../../components/router-link";
import ASINTable from "../../../../components/table";
import { DefaultPerPage, urlDecode } from "../../../../config";
import { filterListDirectory } from "../../../../core/helper/static";
import { ListWrapper } from "../style";
import SuppliersModal from "./suppliersModal";
export default function (props) {
  const {
    GetDirectoriesBrandListAction,
    GetDirectoriesSupplierListAction,
    fakeActionWholesaleSupplier,
    GetActiveCategoryAction,
    fakeActionAppLog,
  } = props;
  const location = useLocation();

  if (!location?.search) {
    return <NoData message="something went wrong" />;
  }
  const state = urlDecode(location);
  const { Flag = "brand", InputId = "", title = "-", domainId = "" } = state;

  const [list, setList] = useState([]);
  const [newTitle, setNewTitle] = useState(title.split("%20").join(" "));
  const [loading, setLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [redirect, setRedirect] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [activeCategoryLoading, setActiveCategoryLoading] = useState(true);
  const [categoriesList, setCategoriesList] = useState([]);
  const [filter, setFilter] = useState({});

  const [openModalView, setOpenModalView] = useState(false);
  const [modalData, setModalData] = useState({});
  const [brand, setBrand] = useState("A");

  const [selectedCategory, setSelectedCategory] = useState(null);

  const GetDirectoriesBrandListRes = useSelector(
    (state) => state.WholesaleSupplier.GetDirectoriesBrandListResponse || {}
  );
  const GetDirectoriesSupplierListRes = useSelector(
    (state) => state.WholesaleSupplier.GetDirectoriesSupplierListResponse || {}
  );
  const GetActiveCategoryRes = useSelector(
    (state) => state.AppLogs.GetActiveCategoryResponse || {}
  );

  useEffect(() => {
    if (GetDirectoriesBrandListRes.status === true) {
      setLoading(false);
      setList(GetDirectoriesBrandListRes?.data?.records);
      setTotalPage(GetDirectoriesBrandListRes?.data?.pagination?.totalCount);
      fakeActionWholesaleSupplier("GetDirectoriesSupplierListResponse");
    } else if (GetDirectoriesBrandListRes === false) {
      setLoading(false);
      fakeActionWholesaleSupplier("GetDirectoriesSupplierListResponse");
    }
  }, [GetDirectoriesBrandListRes]);

  useEffect(() => {
    if (GetDirectoriesSupplierListRes.status === true) {
      setLoading(false);
      setList(GetDirectoriesSupplierListRes?.data?.records);
      setTotalPage(GetDirectoriesSupplierListRes?.data?.pagination?.totalCount);
      fakeActionWholesaleSupplier("GetDirectoriesSupplierListResponse");
    } else if (GetDirectoriesSupplierListRes === false) {
      setLoading(false);
      fakeActionWholesaleSupplier("GetDirectoriesSupplierListResponse");
    }
  }, [GetDirectoriesSupplierListRes]);
  useEffect(() => {
    if (GetActiveCategoryRes.status === true) {
      setActiveCategoryLoading(false);
      setCategoriesList(GetActiveCategoryRes?.data);
      fakeActionAppLog("GetActiveCategoryResponse");
    } else if (GetActiveCategoryRes.status === false) {
      setActiveCategoryLoading(false);
      setCategoriesList([]);
      fakeActionAppLog("GetActiveCategoryResponse");
    }
  }, [GetActiveCategoryRes]);

  const columnsSuppliers = [
    {
      title: "Sr. No.",
      width: 100,
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text?.key}</span>;
      },
    },
    {
      title: "Supplier Name",
      width: 180,
      align: "left",
      render: (text) => {
        return <span>{text.CompanyName || "-"}</span>;
      },
    },
    {
      title: "Products",
      width: 200,
      align: "left",
      render: (text) => {
        return <span>{text.Products || "-"}</span>;
      },
    },
    {
      title: "Telephone",
      width: 200,
      align: "left",
      render: (text) => {
        return <span>{text.ContactNumber || "-"}</span>;
      },
    },
    {
      title: "Action",
      width: 170,
      align: "center",
      render: (text) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            className="text-primary"
            onClick={() => {
              setOpenModalView(true);
              setModalData(text);
            }}
          >
            {/* <ASINTooltip title="View Details">
              <Icons type="eye" style={{ marginRight: "10px" }} />
            </ASINTooltip> */}
            View Details
          </span>
        );
      },
    },
  ];
  const columnsBrand = [
    {
      title: "Sr. No.",
      width: 100,
      align: "left",
      ellipsis: true,
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text?.key}</span>;
      },
    },
    {
      title: "Category",
      width: 180,
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{text.CategoryName || "-"}</span>;
      },
    },
    {
      title: "Brand",
      width: 200,
      align: "left",
      render: (text) => {
        return <span>{text.Brand || "-"}</span>;
      },
    },
    {
      title: "Telephone",
      width: 150,
      align: "left",
      render: (text) => {
        return <span>{text.ContactNumber || "-"}</span>;
      },
    },
    {
      title: "Contact Link",
      width: 150,
      ellipsis: true,
      align: "left",
      render: (text) => {
        if (text.ContactLink) {
          return (
            <Link onClick={() => window.open(text.ContactLinke)}>
              Open Link
            </Link>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: "Website",
      width: 150,
      align: "left",
      ellipsis: true,
      render: (text) => {
        if (text.Website) {
          return (
            <Link onClick={() => window.open(text.Website)}>Open Link</Link>
          );
        }
        return <span>-</span>;
      },
    },
  ];

  const getData = (p, s, b) => {
    if (Flag === "Brand") {
      setLoading(true);
      GetDirectoriesBrandListAction({
        page: p,
        perPage: s,
        SearchValue: searchKeyword,
        InputId: InputId,
        Brand: b,
        CategoryId: filter.categoryId,
        domainId: domainId,
      });
    } else {
      setLoading(true);
      GetDirectoriesSupplierListAction({
        page: p,
        perPage: s,
        SearchValue: searchKeyword,
        InputId: InputId,
        CategoryId: selectedCategory,
        domainId: domainId,
      });
    }
  };

  const onPageNo = (e) => {
    getData(e, pageSize, brand);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    getData(1, e, brand);
  };

  useEffect(() => {
    setPage(1);
    setPageSize(DefaultPerPage);
    getData(1, DefaultPerPage, brand);
  }, [brand, filter, selectedCategory]);

  useEffect(() => {
    setActiveCategoryLoading(true);
    GetActiveCategoryAction(domainId);

    return () => {};
  }, []);

  if (redirect) {
    return <Redirect to="/wholesale-supplier-directory" />;
  }
  return (
    <ListWrapper
      className="content d-flex flex-column flex-column-fluid fadeInRight"
      id="kt_content"
    >
      <div className="container-fluid" id="kt_content_container">
        <div className="row">
          <div className="col-lg-12">
            <div className="title">
              <ASINLink
                to={{
                  pathname: "/wholesale-supplier-directory",
                }}
              >
                <button
                  className="btn btn-light-primary btn-icon"
                  style={{ height: 40 }}
                >
                  <i className="bi bi-arrow-left-short fs-1" />
                </button>
              </ASINLink>
              <h3 className="ms-3 mb-0">{newTitle}</h3>
            </div>
            <div className="card">
              <div className="card-header border-bottom border-bottom-dashed">
                <div
                  className={`d-flex flex-stack flex-wrap gap-4 wholesale_list_suppliers_filters`}
                >
                  {Flag !== "Brand" && (
                    <div className="position-relative">
                      <Select
                        size="large"
                        className="w-200px"
                        loading={activeCategoryLoading}
                        options={
                          categoriesList?.length === 0 ||
                          Array.isArray(categoriesList) === false
                            ? []
                            : categoriesList?.map((d) => {
                                return {
                                  label: d?.name,
                                  value: d?.category_id,
                                };
                              })
                        }
                        allowClear
                        onChange={(e) => {
                          setPage(1);
                          setPageSize(DefaultPerPage);
                          setSelectedCategory(e);
                        }}
                        value={
                          categoriesList.length !== 0 ? selectedCategory : null
                        }
                        placeholder="Select Category"
                      />
                    </div>
                  )}
                  {Flag === "Brand" && (
                    <div className="position-relative mr-4">
                      <Select
                        size="large"
                        className="w-200px"
                        placeholder="Select Category"
                        value={filter?.categoryId || null}
                        loading={activeCategoryLoading}
                        allowClear
                        onChange={(e) => {
                          const filter_ = { ...filter };
                          filter_.categoryId = e || "";
                          setPage(1);
                          setPageSize(DefaultPerPage);
                          setFilter(filter_);
                        }}
                        options={
                          categoriesList?.length === 0 ||
                          Array.isArray(categoriesList) === false
                            ? []
                            : categoriesList?.map((d) => {
                                return {
                                  label: d?.name,
                                  value: d?.category_id,
                                };
                              })
                        }
                      />
                    </div>
                  )}
                  <div className="position-relative">
                    <Input
                      value={searchKeyword}
                      size="large"
                      onChange={(e) => {
                        setSearchKeyword(e.target.value);
                      }}
                      onKeyPress={(ev) => {
                        if (ev?.key === "Enter") {
                          ev?.preventDefault();
                          getData(1, DefaultPerPage);
                          setPage(1);
                          setPageSize(DefaultPerPage);
                        }
                      }}
                      className="ant_common_input"
                      placeholder="Enter Keyword"
                    />
                  </div>
                  <div className="position-relative">
                    <button
                      onClick={() => {
                        setPage(1);
                        setPageSize(DefaultPerPage);
                        getData(1, DefaultPerPage);
                      }}
                      className="btn btn-primary btn-sm fs-7 ps-5 px-5"
                    >
                      <i className="bi bi-search pe-0" />
                    </button>
                  </div>
                </div>
              </div>
              {Flag === "Brand" && (
                <div
                  className="d-flex justify-content-center brandFilter"
                  // style={{ overflow: "auto", }}
                >
                  <div
                    style={{ overflow: "auto", margin: "10px" }}
                    className="d-flex gap-2"
                  >
                    {filterListDirectory?.map((d, i) => (
                      <span
                        onClick={() => {
                          setBrand(d);
                        }}
                        key={i}
                        className={`btn btn-sm btn-flex btn-light fs-6 btn-active-info fw-bolder px-4 ${
                          brand === d ? "active" : ""
                        }`}
                      >
                        {d}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              <div className="separator separator-dashed mb-0" />
              <div className="card-body py-4">
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    {Flag === "Brand" ? (
                      list?.length != 0 ? (
                        <ASINTable
                          columns={columnsBrand}
                          fixed="top"
                          dataSource={list}
                          rowKey="key"
                          loading={loading}
                          pagination={false}
                        />
                      ) : (
                        <NoData />
                      )
                    ) : list?.length != 0 ? (
                      <ASINTable
                        columns={columnsSuppliers}
                        fixed="top"
                        dataSource={list}
                        rowKey="key"
                        loading={loading}
                        pagination={false}
                      />
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModalView && (
        <SuppliersModal
          close={() => setOpenModalView(false)}
          data={modalData}
          show={openModalView}
        />
      )}
    </ListWrapper>
  );
}
