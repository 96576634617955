export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_EXPENSES_TABLE_LIST_SUCCESS":
    case "GET_EXPENSES_TABLE_LIST_ERROR":
      return {
        ...state,
        GetExpensesListResponse: action.updatePayload,
      };

    case "CREATE_EXPENSES_SUCCESS":
    case "CREATE_EXPENSES_ERROR":
      return {
        ...state,
        CreateExpenseResponse: action.updatePayload,
      };

    case "DELETE_EXPENSE_SUCCESS":
    case "DELETE_EXPENSE_ERROR":
      return {
        ...state,
        DeleteExpenseResponse: action.updatePayload,
      };

    case "FAKE_ACTION_EXPENSES":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
