import React from "react";
import { Modal } from "react-bootstrap";
import Wrapper from "./layouts/header/lib/style";
import { useHistory } from "react-router-dom";

export default function (props) {
  const { show, close } = props;
  const history = useHistory();
  return (
    <Modal show={show === true ? true : false} size="md" centered>
      <Wrapper>
        <div className="headerClose">
          <div className="d-flex align-items-center">
            <div className="shimmer logo_feedback">
              <img
                alt="logo"
                className=""
                loading="lazy"
                src="/media/logo-01.png"
              />
            </div>
            <h2 className="mb-0 ms-4">
              Please add SP API credentials and select the VAT settings
            </h2>
          </div>
        </div>
        <div className="my-8">
          You will now be redirected to link amazon seller account page so that
          you can authorize <b>asinwiser</b> to access the resources in your
          Seller Central account.
        </div>
        <div className="d-flex justify-content-center">
          <button
            className={`btn w-150px `}
            id="btnLogout"
            style={{ color: "#fff" }}
            onClick={() => {
              history.replace("/spapi-callback");
              close();
            }}
          >
            Ok
          </button>
        </div>
      </Wrapper>
    </Modal>
  );
}
