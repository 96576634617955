import Auth from "../../modules/auth/auth.reducer";
import User from "../../modules/admin/user/user.reducer";
import AppLogs from "../../modules/admin/application-logs/application-logs.reducer";
import Categories from "../../modules/admin/categories/categories.reducer";
import WholesaleSupplier from "../../modules/wholesale-supplier/wholesale-supplier.reducer";
import ProductsResearch from "../../modules/product-research/product-research.reducer";
import CompetitorResearch from "../../modules/competitor-research/competitor-research.reducer";
import Profile from "../../modules/profile/profile.reducer";
import Setting from "../../modules/setting/setting.reducer";
import OrdersData from "../../modules/manage-reports/orders-data/orders-data.reducers";
import ReturnOrderData from "../../modules/manage-reports/return-order-data/return-order-data.reducers";
import ArchivedInventory from "../../modules/manage-reports/archived-inventory/archived-inventory.reducers";
import ReservedInventory from "../../modules/manage-reports/reserved-inventory/reserved-inventory.reducers";
import FinanceData from "../../modules/manage-reports/finance-data/finance-data.reducers";
import ProductData from "../../modules/manage-reports/product-data/product-data.reducer";
import ReimbursementsData from "../../modules/manage-reports/reimbursements_report/reimbursements-report.reducer";
import DataScheduler from "../../modules/data-scheduler/data-scheduler.reducers";
import Common from "../../modules/common/index.reducer";
import MasterTableBrand from "../../modules/master-tables/brands/brands-reducer";
import MasterTableSKUData from "../../modules/master-tables/sku-data/sku-data.reducer";
import MyList from "../../modules/my-list/my-list.reducer";
import AnalyticsBrand from "../../modules/sales-analytics/sales-analytics.reducer";
import ProfitabilityCalculator from "../../modules/profitability-calculator/index.reducer";
import EstimatedSalesReducer from "../../modules/admin/estimated-sales/estimated-sales.reducer";
import BrandWiseAnalytics from "../../modules/brand-wise-analytics/brand-wise-analytics.reducer";
import Dashboard from "../../modules/dashboard/dashboard.reducer";
import Inventory from "../../modules/inventory/inventory.reducer";
import Price from "../../modules/pricings/pricings.reducer";
import CompanyEmployees from "../../modules/manage-company-employee/index.reducer";
import RepricingProduct from "../../modules/repricing/products/products.reducer";
import RepricingRules from "../../modules/repricing/amazon-repricing-rules/amazon-repricing-rules.reducer";
import PaymentsHistory from "../../modules/payment-history/index.reducer";
import BulkProfitability from "../../modules/bulk-profitability-calculator/index.reducer";
import BrandRules from "../../modules/admin/brand-rules/index.reducer";
import AppConfiguration from "../../modules/admin/setting/app-configuration/index.reducer";
import ListingOptimizer from "../../modules/listing-optimizier/index.reducer";
import PlanningInventory from "../../modules/manage-reports/planning-inventory/index.reducer";
import StrandedInventory from "../../modules/manage-reports/stranded-inventory/index.reducer";
import SqsMessages from "../../modules/application-logs/sqs-messages/index.reducer";
import RepricingNotification from "../../modules/application-logs/repricing-notification/index.reducer";
import AdminScheduler from "../../modules/admin/setting/admin-scheduler/index.reducer";
import Expenses from "../../modules/expenses/expenses.reducer";
import ReviewAnalysis from "../../modules/review-analysis/review-analytics.reducer";
import CheckSubscription from "../../modules/pageJs.reducer";
import SupplierSetup from "../../modules/master-tables/supplier-setup/supplier-setup.reducer";
import Reimbursement from "../../modules/reimbursement/index.reducer";
import POList from "../../modules/po_list/po_list.reducer";
import AdvancedRepricingRules from "../../modules/repricing/advanced-repricing-rules/advanced-repricing-rules.reducer";
const reducers = {
  Auth,
  User,
  AppLogs,
  Setting,
  Profile,
  Dashboard,
  Categories,
  WholesaleSupplier,
  ProductsResearch,
  CompetitorResearch,
  OrdersData,
  ReturnOrderData,
  ArchivedInventory,
  ReservedInventory,
  Inventory,
  FinanceData,
  ProductData,
  ReimbursementsData,
  DataScheduler,
  Common,
  MasterTableBrand,
  MasterTableSKUData,
  MyList,
  AnalyticsBrand,
  ProfitabilityCalculator,
  EstimatedSalesReducer,
  BrandWiseAnalytics,
  Price,
  CompanyEmployees,
  RepricingProduct,
  RepricingRules,
  PaymentsHistory,
  BulkProfitability,
  BrandRules,
  AppConfiguration,
  ListingOptimizer,
  PlanningInventory,
  StrandedInventory,
  SqsMessages,
  RepricingNotification,
  AdminScheduler,
  Expenses,
  ReviewAnalysis,
  CheckSubscription,
  SupplierSetup,
  Reimbursement,
  POList,
  AdvancedRepricingRules,
};

export default reducers;
