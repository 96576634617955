import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import PageLoader from "../../../../components/page-loader";
import { urlDecode } from "../../../../config";

export default function (props) {
  const { userActiveAction, fakeActionAuth } = props;
  const location = useLocation();

  const [redirect, setRedirect] = useState(false);
  const UserActiveRes = useSelector(
    (state) => state.Auth.UserActiveResponse || {}
  );

  useEffect(() => {
    if (UserActiveRes?.status === true) {
      message.destroy();
      message.success(
        UserActiveRes?.message || "User has been activated successfully"
      );
      setRedirect(true);
      fakeActionAuth('UserActiveResponse');
    } else if (UserActiveRes?.status === false) {
      message?.destroy();
      if (UserActiveRes?.error || UserActiveRes?.error_data) {
        message.warning(
          Object.values(
            UserActiveRes?.error || UserActiveRes?.error_data
          )?.[0]?.[0] ||
            UserActiveRes?.message ||
            "Something Went Wrong."
        );
      } else {
        message.warning("Something Went Wrong.");
      }
      setRedirect(true);
      fakeActionAuth('UserActiveResponse');
    }
  }, [UserActiveRes]);

  useEffect(() => {
    const state = urlDecode(location)
    if (state?.email) {
      userActiveAction({ email: state?.email })
    }
    return () => {};
  }, []);

  if (redirect || !urlDecode(location)?.email) {
      return <Redirect to="/login" />;
  }
  document.title = "User Active";
  return (
    <div className="authFadeInBottom" style={{ height: '100%' }}>
      <PageLoader />
    </div>
  );
}
