export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_DIRECTORIES_LIST_SUCCESS":
    case "GET_DIRECTORIES_LIST_ERROR":
      return {
        ...state,
        GetDirectoriesListResponse: action.updatePayload,
      };
    case "GET_DIRECTORIES_BRAND_LIST_SUCCESS":
    case "GET_DIRECTORIES_BRAND_LIST_ERROR":
      return {
        ...state,
        GetDirectoriesBrandListResponse: action.updatePayload,
      };
    case "DIRECTORIES_DELETE_SUCCESS":
    case "DIRECTORIES_DELETE_ERROR":
      return {
        ...state,
        DirectoriesDeleteResponse: action.updatePayload,
      };
    case "IMPORT_WHOLESALES_SUPPLIER_SUCCESS":
    case "IMPORT_WHOLESALES_SUPPLIER_ERROR":
      return {
        ...state,
        ImportWholesalesSupplierResponse: action.updatePayload,
      };
    case "GET_DIRECTORIES_SUPPLIER_LIST_SUCCESS":
    case "GET_DIRECTORIES_SUPPLIER_LIST_ERROR":
      return {
        ...state,
        GetDirectoriesSupplierListResponse: action.updatePayload,
      };
    case "FAKE_ACTION_WHOLESALE_SUPPLIER":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
