import axiosCall from "../../configurations/network-services/axiosCall";

const directoryPrefix = "directory";
const directoriesPrefix = "directories";

export const GetDirectoriesListAction = (data) => {
  const path = `${directoriesPrefix}?page=${data?.page || 1}&perPage=${
    data?.perPage || 10
  }&DomainId=${data?.DomainId || ""}`;
  const responseType = "GET_DIRECTORIES_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const DirectoriesDeleteAction = (DirectoryId) => {
  const path = `${directoriesPrefix}/delete?DirectoryId=${DirectoryId || ''}`;
  const responseType = "DIRECTORIES_DELETE";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const ImportWholesaleSupplierAction = (data) => {
  const path = `${directoriesPrefix}/import`;
  const responseType = "IMPORT_WHOLESALES_SUPPLIER";

  let FormData_ = new FormData();
  FormData_.append("name", data?.name || '');
  FormData_.append("domain_id", data?.domain_id || '');
  FormData_.append("directory_file", data?.directory_file || '');
  FormData_.append("directory_type", data?.directory_type || '');

  return axiosCall("post", path, responseType, FormData_, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetDirectoriesBrandListAction = (data) => {
  const path = `${directoryPrefix}/brand-contact?page=${
    data?.page || 1
  }&perPage=${data?.perPage || 10}&SearchValue=${
    data.SearchValue || ""
  }&DirectoryId=${data?.InputId || ""}&CategoryId=${
    data?.CategoryId || ""
  }&Brand=${data?.Brand || ""}&DomainId=${data?.domainId || ""}`;
  const responseType = "GET_DIRECTORIES_BRAND_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetDirectoriesSupplierListAction = (data) => {
  const path = `${directoryPrefix}/supplier?page=${data?.page || 1}&DomainId=${
    data?.domainId || ""
  }&perPage=${data?.perPage || 10}&SearchValue=${
    data.SearchValue || ""
  }&DirectoryId=${data?.InputId || ""}&CategoryId=${data?.CategoryId || ""}`;
  const responseType = "GET_DIRECTORIES_SUPPLIER_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionWholesaleSupplier = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_WHOLESALE_SUPPLIER", state: data });
};
