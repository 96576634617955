export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_PO_LIST_SUCCESS":
    case "GET_PO_LIST_ERROR":
      return {
        ...state,
        GetPOListListResponse: action.updatePayload,
      };

    case "GET_PO_SKU_LIST_SUCCESS":
    case "GET_PO_SKU_LIST_ERROR":
      return {
        ...state,
        GetPOSKUListResponse: action.updatePayload,
      };

    case "CREATE_PO_DETAILS_SUCCESS":
    case "CREATE_PO_DETAILS_ERROR":
      return {
        ...state,
        CreatePoDetailsResponse: action.updatePayload,
      };

    case "DELETE_PO_LIST_ROW_SUCCESS":
    case "DELETE_PO_LIST_ROW_ERROR":
      return {
        ...state,
        DeletePOListRowResponse: action.updatePayload,
      };

    case "GET_REPLENISHMENT_ON_ORDERED_PRODUCT_SUCCESS":
    case "GET_REPLENISHMENT_ON_ORDERED_PRODUCT_ERROR":
      return {
        ...state,
        GetReplenihsmentOnOrderedProductResponse: action.updatePayload,
      };

    case "DELETE_BULK_PO_LIST_ROW_SUCCESS":
    case "DELETE_BULK_PO_LIST_ROW_ERROR":
      return {
        ...state,
        DeleteBulkPOListRowResponse: action.updatePayload,
      };

    case "FAKE_ACTION_PO_LIST":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
