export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_REPRICING_PRODUCT_LIST_SUCCESS":
    case "GET_REPRICING_PRODUCT_LIST_ERROR":
      return {
        ...state,
        GetRepricingProductListResponse: action.updatePayload,
      };
    case "REPRICING_PRODUCT_RULE_UPDATE_SUCCESS":
    case "REPRICING_PRODUCT_RULE_UPDATE_ERROR":
      return {
        ...state,
        RepricingProductRuleUpdateResponse: action.updatePayload,
      };
    case "REPRICING_PRODUCT_UPDATE_SUCCESS":
    case "REPRICING_PRODUCT_UPDATE_ERROR":
      return {
        ...state,
        RepricingProductUpdateResponse: action.updatePayload,
      };
    case "GET_PRICE_CHANGE_HISTORY_LIST_SUCCESS":
    case "GET_PRICE_CHANGE_HISTORY_LIST_ERROR":
      return {
        ...state,
        GetPriceChangeHistoryListResponse: action.updatePayload,
      };
    case "REPRICING_PRODUCT_RULES_SUCCESS":
    case "REPRICING_PRODUCT_RULES_ERROR":
      return {
        ...state,
        RepricingProductRulesResponse: action.updatePayload,
      };
    case "REPRICING_PRODUCT_NOTIFICATION_LIST_SUCCESS":
    case "REPRICING_PRODUCT_NOTIFICATION_LIST_ERROR":
      return {
        ...state,
        RepricingProductNotificationListResponse: action.updatePayload,
      };
    case "REPRICING_PRODUCT_NOTIFICATION_READ_SUCCESS":
    case "REPRICING_PRODUCT_NOTIFICATION_READ_ERROR":
      return {
        ...state,
        RepricingProductNotificationReadResponse: action.updatePayload,
      };
    case "REPRICING_PRODUCT_IMPORT_SUCCESS":
    case "REPRICING_PRODUCT_IMPORT_ERROR":
      return {
        ...state,
        RepricingProductImportResponse: action.updatePayload,
      };
    case "FAKE_ACTION_REPRICING_PRODUCT":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
