import axiosCall from "../../../configurations/network-services/axiosCall";
const prefix = "admin";
export const GetSystemEventProcessAction = (data) => {
  const path = `${prefix}/system-event-process?page=${
    data?.page || ""
  }&per-page=${data?.perPage || 20}&event_status=${
    data?.event_status || ""
  }&domainId=${data?.domainId || ""}&categoryId=${
    data?.categoryId || ""
  }&event_name=${data?.event_name || ""}&saleRankRange=${
    data?.saleRankRange || ""
  }`;
  const responseType = "ADMIN_SYSTEM_EVENT_PROCESS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetSaleRankRangeAction = () => {
  const path = `sale-rank-range`;
  const responseType = "GET_SALE_RANK_RANGE";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetSystemEventLogsAction = (data) => {
  const path = `system-event-logs?page=${data?.page || ""}&per-page=${
    data?.perPage || 20
  }`;
  const responseType = "ADMIN_SYSTEM_EVENT_LOGS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetUserSystemEventLogsAction = (data) => {
  const path = `user/system-event-logs?page=${data?.page || ""}&per-page=${
    data?.perPage || 20
  }`;
  const responseType = "USER_SYSTEM_EVENT_LOGS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetCentralEventProcessAction = (data) => {
  const path = `user/system-event-process?page=${data?.page || ""}&per-page=${
    data?.perPage || 20
  }&event_status=${data?.event_status || ""}&event_type=${
    data?.event_type || ""
  }&event_name=${data?.event_name || ""}&priority=${
    data?.priority || ""
  }&marketplace_id=${data?.marketplace || ""}`;
  const responseType = "USER_SYSTEM_EVENT_PROCESS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetActiveCategoryAction = (marketplaceId) => {
  const path = `active-category?domain_id=${marketplaceId || ""}`;
  const responseType = "GET_ACTIVE_CATEGORY";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetEventNameListAction = () => {
  const path = `event-name-list`;
  const responseType = "GET_EVENT_NAME_LIST_USER";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SyncAsinAdminAction = (id) => {
  const path = `${prefix}/get-asin-list/${id}`;
  const responseType = "SYNC_ASIN_ADMIN";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionAppLog = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_APP_LOGS", state: data });
};
