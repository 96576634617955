import styled from "styled-components";

const AuthWrapper = styled.div`
  background-color: #f9fafb;
  .ant-input-password {
    height: 40.91px !important;
    input {
      ${'' /* height: 40.9px !important; */}
    }
  }
  .hideScroll::-webkit-scrollbar-track {
    ${'' /* transition: all 0.8s ease 0s;
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset !important; */}
  }
  .ant-input-affix-wrapper {
    background-color: #e8f0fe !important;
    border-color: #d9d9d9 !important;
  }
  // @media (max-width: 768) {
  //   .h-100vh {
  //     height: none !important;
  //   }
  // }
  @media (max-width: 992) {
    .h-100vh {
      height: auto !important;
    }
  }
  .ant-input {
    background-color: #e8f0fe !important;
    :where(.css-dev-only-do-not-override-1c7vl5h).ant-input:hover,
    :where(.css-dev-only-do-not-override-1c7vl5h).ant-input:focus {
      border-color: #d9d9d9 !important;
      background-color: #e8f0fe !important;
      border-inline-end-width: 1px;
    }
  }
`;
export default AuthWrapper;
