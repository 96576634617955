export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_INVENTORY_DATA_SUCCESS":
    case "GET_INVENTORY_DATA_ERROR":
      return {
        ...state,
        GetInventoryDataResponse: action.updatePayload,
      };
    case "GET_INVENTORY_DETAILS_DATA_SUCCESS":
    case "GET_INVENTORY_DETAILS_DATA_ERROR":
      return {
        ...state,
        GetInventoryDetailsDataResponse: action.updatePayload,
      };

    case "GET_REPLENISHMENT_DATA_SUCCESS":
    case "GET_REPLENISHMENT_DATA_ERROR":
      return {
        ...state,
        GetReplenishmentDataResponse: action.updatePayload,
      };

    case "UPDATE_REPLENISHMENT_DATA_SUCCESS":
    case "UPDATE_REPLENISHMENT_DATA_ERROR":
      return {
        ...state,
        UpdateReplenishmentDataResponse: action.updatePayload,
      };
    case "GET_SUPPLIER_LIST_DATA_SUCCESS":
    case "GET_SUPPLIER_LIST_DATA_ERROR":
      return {
        ...state,
        GetSupplierListResponse: action.updatePayload,
      };
    case "UPDATE_REPLENISH_BULK_DATA_SUCCESS":
    case "UPDATE_REPLENISH_BULK_DATA_ERROR":
      return {
        ...state,
        UpdateReplenishBulkDataResponse: action.updatePayload,
      };
    case "GET_REPLENISHMENT_FORECAST_DATA_SUCCESS":
    case "GET_REPLENISHMENT_FORECAST_DATA_ERROR":
      return {
        ...state,
        GetReplenishmentForecastDataResponse: action.updatePayload,
      };
    case "GET_REPLENISHMENT_WAREHOUSE_LIST_SUCCESS":
    case "GET_REPLENISHMENT_WAREHOUSE_LIST_ERROR":
      return {
        ...state,
        GetReplenishmentWarehouseListResponse: action.updatePayload,
      };
    case "ADD_EDIT_REPLENISHMENT_WAREHOUSE_DETAILS_SUCCESS":
    case "ADD_EDIT_REPLENISHMENT_WAREHOUSE_DETAILS_ERROR":
      return {
        ...state,
        AddEditReplenishmentWarehouseDetailsResponse: action.updatePayload,
      };
    case "DELETE_REPLENISHMENT_WAREHOUSE_DETAILS_SUCCESS":
    case "DELETE_REPLENISHMENT_WAREHOUSE_DETAILS_ERROR":
      return {
        ...state,
        DeleteReplenishmentWarehouseResponse: action.updatePayload,
      };

    case "GET_REPLENISHMENT_PROFIT_TAB_DATA_SUCCESS":
    case "GET_REPLENISHMENT_PROFIT_TAB_DATA_ERROR":
      return {
        ...state,
        GetReplenishmentProfitTabResponse: action.updatePayload,
      };

    case "GET_SUPPLIER_DATA_SUCCESS":
    case "GET_SUPPLIER_DATA_ERROR":
      return {
        ...state,
        GetSupplierDetailsResponse: action.updatePayload,
      };

    case "GET_INVENTORY_TAB_DETAILS_SUCCESS":
    case "GET_INVENTORY_TAB_DETAILS_ERROR":
      return {
        ...state,
        GetInventoryTabResponse: action.updatePayload,
      };

    case "GET_UNIT_SOLD_HISTORY_DATA_SUCCESS":
    case "GET_UNIT_SOLD_HISTORY_DATA_ERROR":
      return {
        ...state,
        GetUnitSoldHistoryResponse: action.updatePayload,
      };

    case "GET_COGS_TAB_DETAILS_SUCCESS":
    case "GET_COGS_TAB_DETAILS_ERROR":
      return {
        ...state,
        GetCOGSTabDetailsResponse: action.updatePayload,
      };

    case "GET_COMPETITOR_DATA_SUCCESS":
    case "GET_COMPETITOR_DATA_ERROR":
      return {
        ...state,
        GetCompetitorDetailsResponse: action.updatePayload,
      };

    case "GET_FORECAST_TAB_DETAILS_SUCCESS":
    case "GET_FORECAST_TAB_DETAILS_ERROR":
      return {
        ...state,
        GetForecastDetailsResponse: action.updatePayload,
      };

    case "IMPORT_WAREHOUSE_STOCK_DATA_SUCCESS":
    case "IMPORT_WAREHOUSE_STOCK_DATA_ERROR":
      return {
        ...state,
        ImportWarehouseStockDataResponse: action.updatePayload,
      };

    case "FAKE_ACTION_INVENTORY":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
