export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_REVIEW_ANALYSIS_LIST_SUCCESS":
    case "GET_REVIEW_ANALYSIS_LIST_ERROR":
      return {
        ...state,
        GetReviewAnalysisListResponse: action.updatePayload,
      };
    case "GET_REVIEW_ANALYSIS_DAYS_SUCCESS":
    case "GET_REVIEW_ANALYSIS_DAYS_ERROR":
      return {
        ...state,
        GetReviewAnalysisDaysResponse: action.updatePayload,
      };
    case "UPDATE_REVIEW_ANALYSIS_DAYS_SUCCESS":
    case "UPDATE_REVIEW_ANALYSIS_DAYS_ERROR":
      return {
        ...state,
        UpdateReviewAnalysisDaysResponse: action.updatePayload,
      };
    case "UPDATE_REVIEW_ANALYSIS_ORDER_DAYS_SUCCESS":
    case "UPDATE_REVIEW_ANALYSIS_ORDER_DAYS_ERROR":
      return {
        ...state,
        UpdateReviewAnalysisOrderDaysResponse: action.updatePayload,
      };
    case "FAKE_ACTION_REVIEW_ANALYSIS":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
