export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_MY_LIST__SUCCESS":
    case "GET_MY_LIST__ERROR":
      return {
        ...state,
        GetMyListListResponse: action.updatePayload,
      };
    case "DELETE_MY_LIST__SUCCESS":
    case "DELETE_MY_LIST__ERROR":
      return {
        ...state,
        DeleteMyListListResponse: action.updatePayload,
      };
    case "FAKE_ACTION_MY_LIST":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
