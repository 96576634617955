import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import WholesaleListSuppliers from "../../../modules/pages/wholesale-supplier/lib/wholesale-list-suppliers";
import {
  GetDirectoriesBrandListAction,
  GetDirectoriesSupplierListAction,
  fakeActionWholesaleSupplier,
} from "../../../redux/modules/wholesale-supplier/wholesale-supplier.action";
import {
  GetActiveCategoryAction,
  fakeActionAppLog,
} from "../../../redux/modules/admin/application-logs/application-logs.action";

const mapStateToProps = (state) => ({
  GetDirectoriesBrandListResponse:
    state.WholesaleSupplier.GetDirectoriesBrandListResponse,
  GetDirectoriesSupplierListResponse:
    state.WholesaleSupplier.GetDirectoriesSupplierListResponse,

  GetActiveCategoryResponse: state.AppLogs.GetActiveCategoryResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetDirectoriesBrandListAction,
      GetDirectoriesSupplierListAction,
      fakeActionWholesaleSupplier,

      GetActiveCategoryAction,
      fakeActionAppLog,
    },
    dispatch
  );

const WholesaleListSuppliersASINWiser = connect(
  mapStateToProps,
  mapDispatchToProps
)(WholesaleListSuppliers);

export default WholesaleListSuppliersASINWiser;
