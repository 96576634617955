import React from "react";

import { Table, Tag } from "antd";
const ASINTable = (props) => {
  const {
    columns,
    fixed,
    dataSource,
    rowKey,
    loading,
    pagination,
    scrollX,
    scrollY,
    isCheckBoxRow,
    rowSelection,
    updateTableView = false,
    ...rest
  } = props;
  const ellipsis = false;
  if (updateTableView) {
    return dataSource?.map((l, j) => {
      const isImage = columns?.filter((d) => d?.title === "Image");
      return (
        <>
          <div className="row d-flex align-items-center mx-0">
            {isImage?.length != 0 && (
              <div className="col-1">
                {isImage[0]?.render({ ...l, key: j }, dataSource, j)}
              </div>
            )}

            <div className={`${isImage?.length != 0 ? "col-11" : "col-12"}`}>
              <div className="row mx-0">
                {columns?.map((d, i) => {
                  if (d?.title === "Image") {
                    return;
                  }
                  if (d?.title === "extraRow") {
                    return (
                      <div
                        className="col-12 d-flex align-items-center my-2"
                        style={{
                          borderRight: "1px solid #80808033",
                        }}
                      >
                        {d?.render({ ...l, key: j }, dataSource, j)}
                      </div>
                    );
                  }
                  return (
                    <div
                      className="col-12 col-md-12 col-lg-4 d-flex align-items-center my-2"
                      style={{
                        borderRight: "1px solid #80808033",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <label className="fs-5" style={{ fontWeight: "600" }}>
                          {d?.title} :
                        </label>
                      </div>
                      <div
                        style={{ width: "50%" }}
                        className="commonTableColumnAlign"
                      >
                        {d?.title == "Sr.No." ? (
                          <Tag color="#7472ee">
                            {d?.render({ ...l, key: j }, dataSource, j)}
                          </Tag>
                        ) : (
                          d?.render({ ...l, key: j }, dataSource, j)
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className="separator separator-dashed my-5"
            style={{
              borderColor: "#00000069",
              boxShadow: "0px 0px 10px 1px #b7b5f1",
            }}
          />
        </>
      );
    });
  }
  return (
    <Table
      columns={columns?.map((d) => {
        return {
          ...d,
          ellipsis:
            d?.ellipsis !== undefined
              ? d?.ellipsis === false
                ? false
                : true
              : ellipsis,
        };
      })}
      fixed={fixed}
      dataSource={dataSource?.map((d, i) => {
        return { ...d, key: i };
      })}
      rowKey={rowKey}
      loading={loading}
      pagination={pagination}
      ellipsis={false}
      scrollableTarget="tableContainer"
      rowSelection={
        isCheckBoxRow
          ? {
              type: "checkbox",
              ...rowSelection,
            }
          : ""
      }
      {...rest}
    />
  );
};

export default ASINTable;
