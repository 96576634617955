export default (state, action) => {
    if (typeof state === "undefined") {
      return [];
    }
    switch (action.type) {
      case "GET_BRAND_RULE_LIST_SUCCESS":
      case "GET_BRAND_RULE_LIST_ERROR":
        return {
          ...state,
          GetBrandRuleListResponse: action.updatePayload,
        };
      case "ADD_BRAND_RULE_SUCCESS":
      case "ADD_BRAND_RULE_ERROR":
        return {
          ...state,
          AddBrandRuleResponse: action.updatePayload,
        };
      case "UPDATE_BRAND_RULE_SUCCESS":
      case "UPDATE_BRAND_RULE_ERROR":
        return {
          ...state,
          UpdateBrandRuleResponse: action.updatePayload,
        };
      case "DELETE_BRAND_RULE_SUCCESS":
      case "DELETE_BRAND_RULE_ERROR":
        return {
          ...state,
          DeleteBrandRuleResponse: action.updatePayload,
        };
      case "STATUS_UPDATE_BRAND_RULE_SUCCESS":
      case "STATUS_UPDATE_BRAND_RULE_ERROR":
        return {
          ...state,
          StatusUpdateBrandRuleResponse: action.updatePayload,
        };
      case "FAKE_ACTION_BRAND_RULE":
        return { ...state, [action?.state]: {} };
      default:
        return state;
    }
  };
  