export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_PLANS_LIST_SUCCESS":
    case "GET_PLANS_LIST_ERROR":
      return {
        ...state,
        GetPlansListResponse: action.updatePayload,
      };
    case "UPDATE_STATUS_PLAN_PRICE_SUCCESS":
    case "UPDATE_STATUS_PLAN_PRICE_ERROR":
      return {
        ...state,
        UpdateStatusPlansResponse: action.updatePayload,
      };
    case "CREATE_PLANS_LIST_SUCCESS":
    case "CREATE_PLANS_LIST_ERROR":
      return {
        ...state,
        CreatePlansListResponse: action.updatePayload,
      };
    case "GET_SERVICES_LIST_SUCCESS":
    case "GET_SERVICES_LIST_ERROR":
      return {
        ...state,
        GetServicesListResponse: action.updatePayload,
      };
    case "CREATE_SERVICES_PRICE_SUCCESS":
    case "CREATE_SERVICES_PRICE_ERROR":
      return {
        ...state,
        CreateServicesResponse: action.updatePayload,
      };
    case "UPDATE_SERVICES_PRICE_SUCCESS":
    case "UPDATE_SERVICES_PRICE_ERROR":
      return {
        ...state,
        UpdateServicesResponse: action.updatePayload,
      };
    case "UPDATE_STATUS_SERVICES_PRICE_SUCCESS":
    case "UPDATE_STATUS_SERVICES_PRICE_ERROR":
      return {
        ...state,
        UpdateStatusServicesResponse: action.updatePayload,
      };
    case "USER_PAYMENT_SUCCESS":
    case "USER_PAYMENT_ERROR":
      return {
        ...state,
        UserPaymentResponse: action.updatePayload,
      };
    case "DELETE_PLAN_SUCCESS":
    case "DELETE_PLAN_ERROR":
      return {
        ...state,
        DeletePlanResponse: action.updatePayload,
      };
    case "UPDATE_PLAN_SUCCESS":
    case "UPDATE_PLAN_ERROR":
      return {
        ...state,
        UpdatePlanResponse: action.updatePayload,
      };
    case "SWITCH_PLAN_SUCCESS":
    case "SWITCH_PLAN_ERROR":
      return {
        ...state,
        SwitchPlanResponse: action.updatePayload,
      };
    case "SUBSCRIPTION_CANCEL_PLAN_SUCCESS":
    case "SUBSCRIPTION_CANCEL_PLAN_ERROR":
      return {
        ...state,
        SubscriptionCancelResponse: action.updatePayload,
      };
    case "FAKE_ACTION_PRICE":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
